import React from 'react';

interface IconProps {
    className?: string;
}

const PersonIcon: React.FC<IconProps> = ({className}) => {
    return (
        <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 16.7969 17.5684"
             className={className}>
            <g>
                <rect height="17.5684" opacity="0" width="16.7969" x="0" y="0"/>
                <path
                    d="M1.66992 17.5586L14.7656 17.5586C15.8105 17.5586 16.4355 17.0703 16.4355 16.2598C16.4355 13.7402 13.2812 10.2637 8.21289 10.2637C3.1543 10.2637 0 13.7402 0 16.2598C0 17.0703 0.625 17.5586 1.66992 17.5586ZM8.22266 8.51562C10.3125 8.51562 12.1289 6.64062 12.1289 4.19922C12.1289 1.78711 10.3125 0 8.22266 0C6.13281 0 4.31641 1.82617 4.31641 4.21875C4.31641 6.64062 6.12305 8.51562 8.22266 8.51562Z"
                    fillOpacity="0.85"/>
            </g>
        </svg>
    );
};

export default PersonIcon;
