import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {cancelReview, confirmReview, getReview, retryReview} from "../services/reviewService";
import {ReviewModel} from "../models/ReviewModel";
import {API_URL} from "../utils/config";
import {PhotoProvider, PhotoView} from 'react-photo-view';
import {ReviewStatus} from "../enums/ReviewStatus";
import Loader from "../components/Loader";
import {getReviewStatusColor, getReviewStatusLabel} from "../utils/enumUtils";

const ReviewReport = () => {
    const {id} = useParams<{ id: string }>();
    const [review, setReview] = useState<ReviewModel | null>(null);
    const [showButtons, setShowButtons] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchPublishAttempts = async () => {
            try {
                const fetchedReview = await getReview(Number(id));
                setReview(fetchedReview);
                setShowButtons(fetchedReview.status === ReviewStatus.FAILED)
                setIsLoading(false)
            } catch (error) {
                console.error("Failed to fetch review:", error);
            }
        };

        void fetchPublishAttempts();
    }, [id]);

    const handleCancelReview = async () => {
        try {
            setShowButtons(false)
            setReview({...review!, status: ReviewStatus.CANCELED});
            await cancelReview(Number(id));
        } catch (error) {
            console.error("Failed to cancel review:", error);
        }
    };

    const handleConfirmReview = async () => {
        try {
            setShowButtons(false)
            setReview({...review!, status: ReviewStatus.APPROVED});
            await confirmReview(Number(id));
        } catch (error) {
            console.error("Failed to confirm review:", error);
        }
    };

    const handleRetryReview = async () => {
        try {
            setShowButtons(false)
            setReview({...review!, status: ReviewStatus.SCHEDULED});
            await retryReview(Number(id));
        } catch (error) {
            console.error("Failed to retry review:", error);
        }
    };

    return (
        <div className="container mx-auto max-w-4xl">
            {isLoading || !review ? (
                    <div className="flex items-center justify-center h-screen">
                        <Loader/>
                    </div>
                ) :
                <div className="layout-content-container flex flex-col max-w-[960px] flex-1 p-4 py-8">
                    <div className="flex flex-row sm:gap-4 mb-2 sm:mb-4 items-start sm:items-center">
                        <h2 className="text-base sm:text-2xl px-2 py-1 sm:py-2 font-bold">
                            Отзыв
                        </h2>
                        <span
                            className={`block sm:mt-0 px-2 py-1 sm:py-2 rounded-xl font-medium ${getReviewStatusColor(review?.status)}`}
                        >
                            {getReviewStatusLabel(review?.status)}
                        </span>
                    </div>
                    <div
                        className="flex flex-col sm:flex-row items-stretch justify-between gap-4 rounded-xl bg-[#FFFFFF] p-4 shadow-[0_0_4px_rgba(0,0,0,0.1)]">
                        <div className="flex flex-1 flex-col gap-4">
                            <div className="flex flex-col gap-1">
                                <p className="text-[#141414] text-base sm:text-lg font-bold leading-tight">
                                    {review?.campaign.name}
                                </p>
                                <p className="text-[#3E4D5B] text-sm sm:text-base font-normal leading-normal">
                                    {review?.message}
                                </p>
                            </div>
                            <div className="flex flex-wrap gap-2">
                                {showButtons && (
                                    <button
                                        onClick={handleRetryReview}
                                        className="flex min-w-[84px] max-w-full cursor-pointer items-center justify-center rounded-xl h-8 px-4 bg-blue-50 text-[#141414] text-sm font-medium leading-normal"
                                    >
                                        <span className="truncate">Повторить</span>
                                    </button>
                                )}
                                {showButtons && (
                                    <button
                                        onClick={handleConfirmReview}
                                        className="flex min-w-[84px] max-w-full cursor-pointer items-center justify-center rounded-xl h-8 px-4 bg-green-50 text-[#141414] text-sm font-medium leading-normal"
                                    >
                                        <span className="truncate">Одобрить</span>
                                    </button>
                                )}
                                {showButtons && (
                                    <button
                                        onClick={handleCancelReview}
                                        className="flex min-w-[84px] max-w-full cursor-pointer items-center justify-center rounded-xl h-8 px-4 bg-red-50 text-[#141414] text-sm font-medium leading-normal"
                                    >
                                        <span className="truncate">Отменить</span>
                                    </button>
                                )}
                                <a href={review?.campaign.twoGisUrl}
                                   target="_blank"
                                   rel="noreferrer"
                                   className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-8 px-4 flex-row-reverse bg-[#F0F2F5] text-[#141414] pr-2 gap-1 text-sm font-medium leading-normal w-fit"
                                >
                                    <div className="text-[#141414]" data-icon="ArrowRight" data-size="18px"
                                         data-weight="regular">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px"
                                             fill="currentColor" viewBox="0 0 256 256">
                                            <path
                                                d="M221.66,133.66l-72,72a8,8,0,0,1-11.32-11.32L196.69,136H40a8,8,0,0,1,0-16H196.69L138.34,61.66a8,8,0,0,1,11.32-11.32l72,72A8,8,0,0,1,221.66,133.66Z"
                                            ></path>
                                        </svg>
                                    </div>
                                    <span className="truncate">Смотреть на 2GIS</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    {review?.publishAttempts && review?.publishAttempts?.length > 0 &&
                        <h2 className="text-xl sm:text-2xl font-bold mt-4 sm:mt-8 mb-2 sm:mb-4">Попытки</h2>}
                    <div className="flex flex-col gap-4">
                        {review?.publishAttempts?.map((attempt, index) => (
                            <div key={index}
                                 className="flex flex-col sm:flex-row items-start justify-between gap-4 rounded-xl bg-[#FFFFFF] p-4 shadow-[0_0_4px_rgba(0,0,0,0.1)]">
                                <p className="text-3xl flex items-center justify-center text-center px-4 font-mono w-12 sm:w-auto">
                                    {index + 1}
                                </p>
                                <div className="flex flex-1 flex-col gap-4">
                                    <div className="flex flex-col gap-1">
                                        <p className="text-gray-600 text-xs font-normal leading-normal font-mono break-words">{attempt.message}</p>
                                    </div>
                                    <div className="flex gap-3 flex-wrap">
                                        <div
                                            className="flex h-8 shrink-0 items-center justify-center gap-x-2 rounded-xl bg-[#F0F2F5] px-4">
                                            <p className="text-gray-600 text-xs font-medium leading-normal">
                                                {new Date(attempt.createdAt).toLocaleString("ru-RU", {timeZone: 'Asia/Almaty'})}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {attempt.screenshotFilename && (
                                    <PhotoProvider bannerVisible={false} maskOpacity={0.5}>
                                        <div
                                            className="relative bg-center bg-no-repeat aspect-video bg-cover rounded-xl flex-1 overflow-hidden">
                                            <PhotoView src={`${API_URL}/files/${attempt.screenshotFilename}`}>
                                                <img
                                                    src={`${API_URL}/files/${attempt.screenshotFilename}`}
                                                    alt=""
                                                    className="w-full h-full object-cover rounded-xl"
                                                />
                                            </PhotoView>
                                        </div>
                                    </PhotoProvider>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div>
    );
};


export default ReviewReport;
