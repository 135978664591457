import React, {useEffect, useRef, useState} from 'react';

import {downloadAuditReport, downloadAuditReports, fetchAudits} from '../services/auditService';
import {AuditModel} from '../models/AuditModel';
import Button from "../components/Button";
import {useNavigate, useSearchParams} from 'react-router-dom';
import {AuditStatus} from "../enums/AuditStatus";
import Loader from "../components/Loader";
import KebabIcon from "../icons/Kebab";
import {getAuditStatusColor, getAuditStatusLabel} from "../utils/enumUtils";
import ReportIcon from "../icons/Report";
import ReviewIcon from "../icons/Review";
import {auditsSocket} from "../utils/socket";

const AuditsPage = () => {
    const [audits, setAudits] = useState<AuditModel[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const pageSize = 30;
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedAudit, setSelectedAudit] = useState<AuditModel | null>(null);
    const dropDown = useRef<HTMLDivElement | null>(null)
    const page = parseInt(searchParams.get('page') || '1', 10);
    const selectedStatus = searchParams.get('status') as AuditStatus || null;
    const auditName = searchParams.get('audit') || '';
    const [selectedAudits, setSelectedAudits] = useState<number[]>([]);

    const handleCheckboxChange = (id: number) => {
        setSelectedAudits((prev) =>
            prev.includes(id) ? prev.filter((auditId) => auditId !== id) : [...prev, id]
        );
    };

    const handleAuditDownload = async (auditId: number, auditName: string) => {
        await downloadAuditReport(auditId, auditName)
        setDropdownOpen(false)
    }

    const handleAuditsDownload = async () => {
        await downloadAuditReports(selectedAudits)
        setSelectedAudits([]);
        setDropdownOpen(false)
    }

    const handleAuditReviews = async (auditId: number) => {
        navigate(`/audit/${auditId}/reviews`)
        setDropdownOpen(false)
    }

    const handleStatusChange = (status: AuditStatus) => {
        const params: Record<string, string> = {
            page: "1",
        };

        if (status) {
            params.status = status;
        }
        if (searchParams.get('audit')) {
            params.audit = searchParams.get('audit')!;
        }

        setSearchParams(params);
    };

    const handleAuditNameChange = (auditName: string) => {
        const params: Record<string, string> = {
            page: "1",
        };

        if (searchParams.get('status')) {
            params.status = searchParams.get('status')!;
        }
        if (auditName) {
            params.audit = auditName;
        }

        setSearchParams(params);
    };

    const toggleDropdown = (audit: AuditModel) => {
        setSelectedAudit(audit);
        setDropdownOpen(!dropdownOpen);
    };

    const closeDropDowns = (e: MouseEvent) => {
        const target = e.target as HTMLElement;

        if (dropdownOpen && !dropDown.current?.contains(target)) {
            setDropdownOpen(false)
        }
    }

    document.addEventListener('mousedown', closeDropDowns)

    useEffect(() => {
        const loadAudits = async () => {
            try {
                setIsLoading(true)
                const pagination = await fetchAudits(page, pageSize, selectedStatus, auditName);
                setAudits(pagination.data);
                setTotalPages(pagination.meta.totalPages);
                setTotalItems(pagination.meta.totalItems)
                setIsLoading(false)
            } catch (error) {
                console.error('Failed to load audits', error);
            }
        };

        void loadAudits();
    }, [page, selectedStatus, auditName]);


    useEffect(() => {
        auditsSocket.connect();

        auditsSocket.on('connect_error', (error) => {
            console.error('Connection error: ', error);
        });

        auditsSocket.on('update', (updatedAudit: AuditModel) => {
            setAudits((prevAudits) =>
                prevAudits.map((profile) =>
                    profile.id === updatedAudit.id
                        ? {...profile, ...updatedAudit}
                        : profile
                )
            );
        });

        return () => {
            auditsSocket.off('connect_error');
            auditsSocket.off('update');
        };
    }, []);

    const handleNextPage = () => {
        if (page < totalPages) setSearchParams({page: (page + 1).toString()});
    };

    const handlePrevPage = () => {
        if (page > 1) setSearchParams({page: (page - 1).toString()});
    };

    return (
        <div className="container mx-auto">
            <div className="flex flex-row items-center align-middle justify-between mb-4 sm:mb-6">
                <div className="flex flex-row gap-2 sm:gap-4 items-center">
                    <h1 className="text-2xl sm:text-4xl font-bold mb-0">Аудиты</h1>
                    <span
                        className={`text-sm sm:text-base block sm:mt-0 px-3 py-1 sm:py-2 rounded-xl font-medium bg-primary`}>{totalItems}</span>
                </div>
                {(selectedAudits.length > 0) ? (
                    <Button
                        label="Экспорт"
                        onClick={() => handleAuditsDownload()}
                        className="px-6 py-2 sm:px-10 bg-teal-500 hover:bg-teal-600"
                    />
                ) : (
                    <Button
                        label="Создать"
                        onClick={() => navigate('/create-audit')}
                        className="px-6 py-2 sm:px-10"
                    />
                )}
            </div>

            <div className="flex flex-row gap-2">
                {/* Dropdown Filter */}
                <div className="relative mb-4 w-full sm:w-fit">
                <span className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        fill="currentColor"
                        viewBox="0 0 256 256"
                    >
                        <path
                            d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"
                        ></path>
                    </svg>
                </span>
                    <select
                        className="w-full bg-primary appearance-none rounded-lg py-2 pl-4 pr-10 text-gray-700 shadow-sm focus:outline-none"
                        value={selectedStatus || ""}
                        onChange={(e) => handleStatusChange(e.target.value as AuditStatus)}
                    >
                        <option value="">Все статусы</option>
                        {Object.values(AuditStatus).map((status: AuditStatus) => (
                            <option key={status} value={status}>{getAuditStatusLabel(status)}</option>))}
                    </select>
                </div>

                <div className="relative mb-4 w-full sm:w-fit">
                    <input
                        type="text"
                        className="bg-primary rounded-lg py-2 pl-4 text-gray-700 shadow-sm focus:outline-none"
                        placeholder="Наименование"
                        value={auditName || ""}
                        onChange={(e) => handleAuditNameChange(e.target.value)}
                    />
                </div>
            </div>

            <div className="border border-gray-200 rounded-lg overflow-x-scroll">
                <table className="table-auto w-full text-center whitespace-no-wrap">
                    <thead>
                    {!isLoading && <tr className="border-b border-gray-200">
                        <th className="px-2 sm:px-4 py-5 font-medium"/>
                        <th className="px-2 sm:px-4 py-5 font-medium text-gray-500">#</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Наименование</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Город</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Рейтинг</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Статус</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">2GIS</th>
                        <th className="px-2 sm:px-4 py-5 font-medium hidden sm:table-cell">Дата создания</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Действия</th>
                    </tr>}
                    </thead>
                    <tbody>
                    {isLoading ? (
                        <tr>
                            <td colSpan={9} className="px-4 py-4 text-center">
                                <Loader/>
                            </td>
                        </tr>
                    ) : audits.length === 0 ? (
                        <tr>
                            <td colSpan={9} className="px-4 py-4 text-center text-gray-500">
                                Аудиты не найдены
                            </td>
                        </tr>
                    ) : (
                        audits.map((audit, index) => (
                            <tr key={index} className="border-t border-gray-200 hover:bg-gray-100">
                                <td className="px-2 sm:px-4 py-2">
                                    <input
                                        type="checkbox"
                                        className="w-5 h-5 cursor-pointer"
                                        checked={selectedAudits.includes(audit.id)}
                                        onChange={() => handleCheckboxChange(audit.id)}
                                    />
                                </td>
                                <td className="px-2 sm:px-4 py-2 text-gray-500">{audit.id}</td>
                                <td className="px-2 sm:px-4 py-2 max-w-48">{audit.name}</td>
                                <td className="px-2 sm:px-4 py-2">{audit.city}</td>
                                <td className="px-2 sm:px-4 py-2">{audit.rating?.toFixed(2)}</td>
                                <td className="px-2 sm:px-4 py-2">
                                    <span
                                        className={`block w-full px-2 py-1 sm:py-2 rounded-xl font-medium ${getAuditStatusColor(audit.status)}`}
                                    >
                                        {getAuditStatusLabel(audit.status)}
                                    </span>
                                </td>
                                <td className="px-2 sm:px-4 py-2">
                                    <a target="_blank" rel="noreferrer" href={audit.twoGisUrl}
                                       className="text-blue-500 hover:underline cursor-pointer">
                                        Ссылка
                                    </a>
                                </td>
                                <td className="px-2 sm:px-4 py-2 hidden sm:table-cell">{new Date(audit.createdAt).toLocaleString("ru-RU", {timeZone: 'Asia/Almaty'})}</td>
                                <td className="px-2 sm:px-4 py-2 relative">
                                    <button
                                        onClick={() => toggleDropdown(audit)}
                                        className="inline-flex items-center p-2 text-sm font-medium text-gray-900 rounded-lg hover:bg-gray-100 focus:outline-none"
                                    >
                                        <KebabIcon className="w-5 h-5"/>
                                    </button>

                                    {/* Dropdown menu */}
                                    {(dropdownOpen && selectedAudit?.id === audit.id) && (
                                        <div ref={dropDown}
                                             className="absolute right-0 bottom-0 z-5 bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-32">
                                            <ul className="py-2 text-sm text-gray-700"
                                                aria-labelledby="dropdownMenuIconButton">
                                                {audit.status === AuditStatus.COMPLETED && (
                                                    <li className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                        onClick={() => handleAuditDownload(audit.id, audit.name)}>
                                                        <ReportIcon className="mr-2 w-5 h-5"/>
                                                        <p className="text-gray-700 hover:text-gray-900">Экспорт</p>
                                                    </li>
                                                )}
                                                {audit.status !== AuditStatus.FAILED && (
                                                    <li className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                        onClick={() => handleAuditReviews(audit.id)}>
                                                        <ReviewIcon className="mr-2 w-5 h-5"/>
                                                        <p className="text-gray-700 hover:text-gray-900">Отзывы</p>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>

            {!isLoading && <div className="mt-4 flex flex-row justify-between items-center">
                <Button
                    label="Назад"
                    onClick={handlePrevPage}
                    disabled={page === 1}
                    className="px-6 py-2 sm:px-10 mb-0 h-10 flex items-center"
                />
                <span
                    className="inline-flex items-center justify-center h-10 leading-none text-center">{page}/{totalPages}</span>
                <Button
                    label="Вперед"
                    onClick={handleNextPage}
                    disabled={page >= totalPages}
                    className="px-6 py-2 sm:px-10 mb-0 h-10 flex items-center"
                />
            </div>}
        </div>
    );

};

export default AuditsPage;
