import {CampaignStatus} from "../enums/CampaignStatus";
import {ReviewStatus} from "../enums/ReviewStatus";
import {ProfileStatus} from "../enums/ProfileStatus";
import {AuditStatus} from "../enums/AuditStatus";
import {AuditReviewStatus} from "../enums/AuditReviewStatus";
import {ToneType} from "../enums/ToneType";

export const getCampaignStatusLabel = (status: CampaignStatus) => {
    switch (status) {
        case CampaignStatus.CANCELED:
            return "Отменена";
        case CampaignStatus.PAUSED:
            return "Пауза";
        case CampaignStatus.FINISHED:
            return "Завершена";
        case CampaignStatus.SCHEDULED:
            return "Запланирована";
        case CampaignStatus.ACTIVE:
            return "Активна";
        default:
            return "Неизвестный статус";
    }
};

export const getReviewStatusLabel = (status: ReviewStatus): string => {
    switch (status) {
        case ReviewStatus.SCHEDULED:
            return "Запланирован";
        case ReviewStatus.MODERATING:
            return "Модерируется";
        case ReviewStatus.APPROVED:
            return "Опубликован";
        case ReviewStatus.REJECTED:
            return "Отклонен";
        case ReviewStatus.FAILED:
            return "Провалился";
        case ReviewStatus.CANCELED:
            return "Отменен";
        case ReviewStatus.IN_QUEUE:
            return "Ожидает";
        case ReviewStatus.PROCESSING:
            return "Выполняется";
        default:
            return "Неизвестный статус";
    }
};

export const getProfileStatusLabel = (status: ProfileStatus): string => {
    switch (status) {
        case ProfileStatus.READY:
            return "Готов";
        case ProfileStatus.GOLOGIN_CREATED:
            return "Регистрация";
        case ProfileStatus.FAILED:
            return "Провалился";
        case ProfileStatus.REGISTRATION_FAILED:
            return "Регистрация провалена";
        case ProfileStatus.NOT_READY:
            return "Выполняется";
        default:
            return "Неизвестный статус";
    }
};

export const getAuditStatusLabel = (status: AuditStatus): string => {
    switch (status) {
        case AuditStatus.IN_PROGRESS:
            return 'Обрабатывается'
        case AuditStatus.REVIEWS_FETCHED:
            return 'Сбор данных'
        case AuditStatus.REVIEWS_ANALYZED:
            return 'Анализ'
        case AuditStatus.FAILED:
            return "Провалился";
        case AuditStatus.COMPLETED:
            return "Завершен";
        default:
            return "Неизвестный статус";
    }
};

export const getAuditReviewStatusLabel = (status: AuditReviewStatus): string => {
    switch (status) {
        case AuditReviewStatus.UNDEFINED:
            return 'Обрабывается'
        case AuditReviewStatus.IN_QUEUE:
            return 'Ожидает'
        case AuditReviewStatus.DEFINED:
            return 'Обработан'
        case AuditReviewStatus.FAILED:
            return "Провалился";
        default:
            return "Неизвестный статус";
    }
};

export const getReviewToneTypesLabel = (toneType: ToneType): string => {
    switch (toneType) {
        case ToneType.NEGATIVE:
            return 'Отрицательная'
        case ToneType.NEUTRAL:
            return 'Нейтральная'
        case ToneType.POSITIVE:
            return 'Положительная'
        default:
            return "Неизвестная";
    }
};

export const getProfileStatusColor = (status: ProfileStatus): string => {
    switch (status) {
        case ProfileStatus.READY:
            return "bg-green-100";
        case ProfileStatus.GOLOGIN_CREATED:
            return "bg-blue-100";
        case ProfileStatus.FAILED:
        case ProfileStatus.REGISTRATION_FAILED:
            return "bg-red-100";
        case ProfileStatus.NOT_READY:
            return "bg-emerald-100";
        default:
            return "bg-neutral-200";
    }
}

export const getReviewStatusColor = (status: ReviewStatus): string => {
    switch (status) {
        case ReviewStatus.SCHEDULED:
            return "bg-blue-100";
        case ReviewStatus.MODERATING:
            return "bg-orange-100";
        case ReviewStatus.APPROVED:
            return "bg-green-100";
        case ReviewStatus.REJECTED:
            return "bg-red-100";
        case ReviewStatus.FAILED:
            return "bg-red-100";
        case ReviewStatus.CANCELED:
            return "bg-gray-100";
        case ReviewStatus.IN_QUEUE:
            return "bg-indigo-200";
        case ReviewStatus.PROCESSING:
            return "bg-emerald-100";
        default:
            return "bg-neutral-200";
    }
}

export const getCampaignStatusColor = (status: CampaignStatus): string => {
    switch (status) {
        case CampaignStatus.ACTIVE:
            return "bg-emerald-100";
        case CampaignStatus.SCHEDULED:
            return "bg-blue-100";
        case CampaignStatus.PAUSED:
            return "bg-orange-100";
        case CampaignStatus.CANCELED:
            return "bg-gray-100";
        case CampaignStatus.FINISHED:
            return "bg-green-100";
        default:
            return "bg-neutral-200";
    }
}

export const getAuditStatusColor = (status: AuditStatus): string => {
    switch (status) {
        case AuditStatus.IN_PROGRESS:
        case AuditStatus.REVIEWS_FETCHED:
        case AuditStatus.REVIEWS_ANALYZED:
            return "bg-emerald-100";
        case AuditStatus.FAILED:
            return "bg-red-100";
        case AuditStatus.COMPLETED:
            return "bg-green-100";
        default:
            return "bg-neutral-200";
    }
}

export const getAuditReviewStatusColor = (status: AuditReviewStatus): string => {
    switch (status) {
        case AuditReviewStatus.IN_QUEUE:
            return "bg-emerald-100";
        case AuditReviewStatus.FAILED:
            return "bg-red-100";
        case AuditReviewStatus.DEFINED:
            return "bg-green-100";
        case AuditReviewStatus.UNDEFINED:
            return "bg-emerald-100";
        default:
            return "bg-neutral-200";
    }
}

export const getReviewToneTypeColor = (toneType: ToneType): string => {
    switch (toneType) {
        case ToneType.NEGATIVE:
            return "bg-rose-100";
        case ToneType.NEUTRAL:
            return "bg-sky-100";
        case ToneType.POSITIVE:
            return "bg-green-100";
        default:
            return "bg-neutral-200";
    }
}

