import React from 'react';

interface IconProps {
    className?: string;
}

const LogoIcon: React.FC<IconProps> = ({className}) => {
    return (
        <svg
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 14 14"
            stroke="currentColor"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.6667 0.333333H9.2222V4.7778H4.7778V9.2222H0.333333V13.6667H13.6667V0.333333Z"
                fill="#0D141C"
            />
        </svg>
    );
};

export default LogoIcon;
