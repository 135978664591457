import {PaginationModel} from "../models/PaginationModel";
import {ReviewStatus} from "../enums/ReviewStatus";
import axiosInstance from '../utils/axiosConfig';
import {ReviewModel} from "../models/ReviewModel";

export async function getReviews(
    page: number,
    limit: number,
    status: ReviewStatus | null,
    campaignName: string | null
): Promise<PaginationModel<ReviewModel>> {
    const params: Record<string, string | number> = {
        page,
        limit,
    };

    if (status) {
        params["filter.status"] = status;
    }

    if (campaignName) {
        params["filter.campaign.name"] = `$ilike:${campaignName}`;
    }

    const response = await axiosInstance.get('/reviews', {params});

    return response.data;
}


export async function retryReview(
    id: number,
): Promise<void> {
    await axiosInstance.patch(`/reviews/${id}/retry`);
}

export async function getReview(
    reviewId: number,
): Promise<ReviewModel> {
    const response = await axiosInstance.get(`/reviews/${reviewId}`);

    return response.data;
}

export async function cancelReview(
    reviewId: number,
): Promise<void> {
    await axiosInstance.patch(`/reviews/${reviewId}/cancel`);
}

export async function confirmReview(
    reviewId: number,
): Promise<void> {
    await axiosInstance.patch(`/reviews/${reviewId}/confirm`);
}
