import React, {useState} from 'react';
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import {CreateAuditModel} from '../models/CreateAuditModel';
import {createAudit} from '../services/auditService';
import {useNavigate} from 'react-router-dom';
import Modal from "../components/Modal";

const CreateAuditPage = () => {
    const [auditName, setAuditName] = useState('');
    const [auditTwoGisUrl, setAuditTwoGisUrl] = useState('');
    const [auditCity, setAuditCity] = useState('');
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCreateAudit = () => {
        if (validateForm()) {
            setIsModalOpen(true);
        }
    };

    const [errors, setErrors] = useState({
        auditName: '',
        twoGisUrl: '',
        auditCity: '',
    });

    const validateForm = () => {
        const newErrors = {
            auditName: '',
            twoGisUrl: '',
            auditCity: '',
        };

        if (!auditName.trim()) {
            newErrors.auditName = 'Название аудита обязательно';
        }

        if (!auditTwoGisUrl.trim()) {
            newErrors.twoGisUrl = '2GIS ссылка обязательна';
        } else if (!/\/firm\/\d+/.test(auditTwoGisUrl)) {
            newErrors.twoGisUrl = 'Неверный формат URL (нужна полная ссылка)';
        }

        if (!auditCity.trim()) {
            newErrors.auditCity = 'Город аудита обязателен';
        }

        setErrors(newErrors);

        return Object.values(newErrors).every(error => error === '');
    };

    const clearError = (field: keyof typeof errors) => {
        setErrors(prevErrors => ({...prevErrors, [field]: ''}));
    };

    const handleConfirmCreation = async () => {
        if (validateForm()) {
            const auditData: CreateAuditModel = {
                name: auditName,
                twoGisUrl: auditTwoGisUrl,
                city: auditCity,
            };

            try {
                await createAudit(auditData);

                setAuditName('');
                setAuditTwoGisUrl('');
                setAuditCity('');
                setErrors({
                    auditName: '',
                    twoGisUrl: '',
                    auditCity: '',
                });

                navigate("/audits")
            } catch (error) {
                console.error("Failed to create audit:", error);
            }
        }
    };

    return (
        <div className="max-w-4xl mx-auto">
            <h1 className="text-3xl font-bold mb-6">Создание аудита</h1>

            <div className="space-y-6">
                <TextInput
                    label="Название"
                    value={auditName}
                    setValue={(value) => {
                        setAuditName(value);
                        clearError('auditName');
                    }}
                    error={errors.auditName}
                />

                <TextInput
                    label="2GIS ссылка"
                    value={auditTwoGisUrl}
                    setValue={(value) => {
                        setAuditTwoGisUrl(value);
                        clearError('twoGisUrl');
                    }}
                    error={errors.twoGisUrl}
                />

                <TextInput
                    label="Город"
                    value={auditCity}
                    setValue={(value) => {
                        setAuditCity(value);
                        clearError('auditCity');
                    }}
                    error={errors.auditCity}
                />

                <Button
                    label="Создать"
                    onClick={handleCreateAudit}
                    disabled={Object.values(errors).some(error => error)}
                />
            </div>

            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onAction={handleConfirmCreation}
                title="Подтверждение создания аудита"
                actionLabel="Подтвердить"
                description="Вы уверены, что хотите создать этот аудит?"
            />
        </div>
    );
};

export default CreateAuditPage;
