import React from 'react';
import Calendar from 'react-calendar';
import '../css/calendar.css';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface CalendarComponentProps {
    selectedDate: Value;
    setSelectedDate: (date: Value) => void;
    label: string;
    error?: string;
    disabled?: boolean;
}

const CalendarComponent: React.FC<CalendarComponentProps> = ({
                                                                 selectedDate,
                                                                 setSelectedDate,
                                                                 error,
                                                             }) => {
    const handleDateChange = (newValue: Value) => {
        setSelectedDate(newValue);
    };


    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return (
        <div>
            <Calendar
                locale={"ru"}
                onChange={handleDateChange}
                value={selectedDate}
                selectRange
                className="my-4 calendar"
                minDate={tomorrow}
            />
            {error && <p className="text-red-500 mt-1">{error}</p>} {/* Show error message if present */}
        </div>
    );
};

export default CalendarComponent;
