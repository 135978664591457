import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getCampaignStatisticsById} from "../services/campaignService";
import {CampaignStatisticsModel} from "../models/CampaignStatisticsModel";
import {RatingDistributionModel} from "../models/RatingDistributionModel";
import {ReviewStatusCountModel} from "../models/ReviewStatusCountModel";
import {ReviewStatus} from "../enums/ReviewStatus";
import {getCampaignStatusLabel, getReviewStatusLabel} from "../utils/enumUtils";

const CampaignStats = () => {
    const {id} = useParams<{ id: string }>();
    const [campaignStatistics, setCampaignStatistics] = useState<CampaignStatisticsModel | null>(null);

    useEffect(() => {
        const fetchCampaign = async () => {
            try {
                const fetchedCampaign = await getCampaignStatisticsById(Number(id));
                setCampaignStatistics(fetchedCampaign);
            } catch (error) {
                console.error("Failed to fetch campaign:", error);
            }
        };

        void fetchCampaign();
    }, [id]);

    return (
        <div className="container mx-auto max-w-4xl py-8">
            <div className="flex flex-1">
                {campaignStatistics && (<div className="layout-content-container flex flex-col flex-1">
                    <h1 className="text-4xl font-bold mb-5">Статистика</h1>
                    <h2 className="font-bold leading-tight pb-3 pt-5">Общая информация</h2>
                    <div className="flex flex-wrap gap-4 mb-5">
                        <StatCard title="Название" value={campaignStatistics.campaign.name}/>
                    </div>
                    <div className="flex flex-wrap gap-4 mb-5">
                        <StatCard title="GPT промпт" value={campaignStatistics.campaign.gptPrompt}/>
                    </div>
                    <div className="flex flex-wrap gap-4">
                        <StatCard title="Период"
                                  value={`${new Date(campaignStatistics.campaign.periodStart).toLocaleDateString("ru-RU")}-${new Date(campaignStatistics.campaign.periodEnd).toLocaleDateString("ru-RU")}`}/>
                        <StatCard title="Период в течение дня"
                                  value={`${campaignStatistics.campaign.dailyStartTime}-${campaignStatistics.campaign.dailyEndTime}`}/>
                        <StatCard title="2GIS" value="Ссылка" url={campaignStatistics.campaign.twoGisUrl}/>
                    </div>
                    <h2 className="font-bold leading-tight pb-3 pt-5">Статистика</h2>
                    <div className="flex flex-wrap gap-4">
                        <StatCard title="Всего отзывов" value={campaignStatistics.totalReviews.toString()}/>
                        <StatCard title="Средняя оценка"
                                  value={(+campaignStatistics.averageRating).toFixed(2)}/>
                        <StatCard title="Статус"
                                  value={getCampaignStatusLabel(campaignStatistics.campaign.status)}/>
                    </div>
                    <h2 className="font-bold leading-tight pb-3 pt-5">Распределение оценок</h2>
                    <div className="flex flex-wrap gap-4">
                        <StatDistribution distribution={campaignStatistics.realRatingsDistribution}
                                          title="Распределение оценок"/>
                    </div>
                    <h2 className="font-bold leading-tight pb-3 pt-5">Распределение статусов</h2>
                    <div className="flex flex-wrap gap-4">
                        <StatDistribution distribution={campaignStatistics.reviewStatusDistribution}
                                          title="Распределение статусов"/>
                    </div>
                </div>)}
            </div>
        </div>
    );
};

const StatCard = (props: { title: string; value: string; url?: string }) => {
    return (
        <div
            onClick={() => props.url && window.open(props.url, "_blank")}
            className={`flex min-w-[158px] flex-1 flex-col gap-2 rounded-xl p-6 border border-[#d0dbe7] ${props.url ? 'cursor-pointer' : ''}`}
        >
            <p className="text-[#0e141b] text-base font-medium leading-normal">{props.title}</p>
            <p className="text-[#0e141b] tracking-light text-xl font-bold leading-tight">{props.value}</p>
        </div>
    );
};

const StatDistribution = (props: {
    title: string;
    distribution: RatingDistributionModel[] | ReviewStatusCountModel[];
}) => {
    let indicators: { label: string; percentage: number }[] = [];

    if (props.distribution.length > 0 && 'rating' in props.distribution[0]) {
        indicators = [5, 4, 3, 2, 1].map((star) => ({
            label: `${star}  ⭐`,
            percentage: +((props.distribution as RatingDistributionModel[]).find(e => e.rating === star)?.count ?? 0),
        }));
    } else if (props.distribution.length > 0 && 'status' in props.distribution[0]) {
        indicators = Object.keys(ReviewStatus).map((status) => ({
            label: getReviewStatusLabel(status as ReviewStatus),
            percentage: +((props.distribution as ReviewStatusCountModel[]).find(e => e.status === status)?.count ?? 0),
        }));
    }

    const totalCount = indicators.reduce((total, rating) => total + rating.percentage, 0);

    return (
        <div className="flex mx-auto flex-1 flex-col gap-2 rounded-xl border border-[#d0dbe7] p-6">
            <div className="grid min-h-[180px] gap-x-4 gap-y-6 grid-cols-[auto_1fr] items-center py-3">
                {indicators.map((rating) => (
                    <React.Fragment key={rating.label}>
                        <p className="text-[#4e7397] text-[13px] font-mono font-bold leading-normal tracking-[0.015em]">{rating.label}</p>
                        <div className="h-full flex-1 relative">
                            <div
                                className="border-[#4e7397] bg-primary border-r-2 h-full"
                                style={{width: totalCount > 0 ? `${(rating.percentage / totalCount) * 100}%` : '0%'}}
                            ></div>
                            <span className="absolute top-0.5 right-1 align-middle text-xs text-[#4e7397] font-medium">
                                {totalCount > 0 ? `${Math.round((rating.percentage / totalCount) * 100)}%` : '0%'}
                            </span>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};



export default CampaignStats;
