import React, {useEffect, useState} from 'react';
import Button from "../components/Button";
import {useParams, useSearchParams} from 'react-router-dom';
import Loader from "../components/Loader";
import {
    getAuditReviewStatusColor,
    getAuditReviewStatusLabel,
    getReviewToneTypeColor,
    getReviewToneTypesLabel
} from "../utils/enumUtils";
import {fetchAuditsReviews} from "../services/auditService";
import {AuditReviewModel} from "../models/AuditReviewModel";
import {AuditReviewStatus} from "../enums/AuditReviewStatus";
import {auditsReviewsSocket, auditsSocket} from "../utils/socket";

const AuditReviewsPage = () => {
    const {id: auditId} = useParams<{ id: string }>();
    const [reviews, setReviews] = useState<AuditReviewModel[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const pageSize = 30;
    const [isLoading, setIsLoading] = useState(false);
    const page = parseInt(searchParams.get('page') || '1', 10);
    const selectedStatus = searchParams.get('status') as AuditReviewStatus || null;

    const handleStatusChange = (status: AuditReviewStatus) => {
        const params: Record<string, string> = {
            page: "1",
        };

        if (status) {
            params.status = status;
        }

        setSearchParams(params);
    };

    useEffect(() => {
        const loadReviews = async () => {
            if (!auditId)
                return

            try {
                setIsLoading(true)
                const pagination = await fetchAuditsReviews(auditId, page, pageSize, selectedStatus);
                setReviews(pagination.data);
                setTotalPages(pagination.meta.totalPages);
                setTotalItems(pagination.meta.totalItems)
                setIsLoading(false)
            } catch (error) {
                console.error('Failed to load reviews', error);
            }
        };

        void loadReviews();
    }, [auditId, page, selectedStatus]);


    useEffect(() => {
        auditsReviewsSocket.connect();

        auditsReviewsSocket.on('connect_error', (error) => {
            console.error('Connection error:', error);
        });

        auditsReviewsSocket.on('update', (updatedReview: AuditReviewModel) => {
            setReviews((prevReviews) =>
                prevReviews.map((review) =>
                    review.id === updatedReview.id
                        ? {...review, ...updatedReview}
                        : review
                )
            );
        });

        auditsSocket.on('create', (createdReview: AuditReviewModel) => {
            setReviews((prevProfiles) => [createdReview, ...prevProfiles]);
        });

        return () => {
            auditsSocket.off('connect_error');
            auditsSocket.off('update');
            auditsSocket.off('create');
        };
    }, []);

    const handleNextPage = () => {
        if (page < totalPages) setSearchParams({page: (page + 1).toString()});
    };

    const handlePrevPage = () => {
        if (page > 1) setSearchParams({page: (page - 1).toString()});
    };

    return (
        <div className="container mx-auto">
            <div className="flex flex-row items-center align-middle justify-between mb-4 sm:mb-6">
                <div className="flex flex-col mb-0">
                    <div className="flex flex-row gap-2 sm:gap-4 items-center">
                        <h1 className="text-2xl sm:text-4xl font-bold mb-2">Отзывы</h1>
                        <span
                            className={`text-sm sm:text-base block sm:mt-0 px-3 py-1 sm:py-2 rounded-xl font-medium bg-primary`}>{totalItems}</span>
                    </div>
                    <p className="text-base mb-0">Для аудита #{auditId}</p>
                </div>
            </div>

            <div className="flex flex-row gap-2">
                {/* Dropdown Filter */}
                <div className="relative mb-4 w-full sm:w-fit">
                <span className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        fill="currentColor"
                        viewBox="0 0 256 256"
                    >
                        <path
                            d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"
                        ></path>
                    </svg>
                </span>
                    <select
                        className="w-full bg-primary appearance-none rounded-lg py-2 pl-4 pr-10 text-gray-700 shadow-sm focus:outline-none"
                        value={selectedStatus || ""}
                        onChange={(e) => handleStatusChange(e.target.value as AuditReviewStatus)}
                    >
                        <option value="">Все статусы</option>
                        {Object.values(AuditReviewStatus).map((status: AuditReviewStatus) => (
                            <option key={status} value={status}>{getAuditReviewStatusLabel(status)}</option>))}
                    </select>
                </div>
            </div>

            <div className="border border-gray-200 rounded-lg overflow-x-scroll">
                <table className="table-auto w-full text-center whitespace-no-wrap">
                    <thead>
                    {!isLoading && <tr className="border-b border-gray-200">
                        <th className="px-2 sm:px-4 py-5 font-medium text-gray-500">#</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Отзыв</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Оценка</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Тональность</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Статус</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Ответ</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Дата публикации</th>
                    </tr>}
                    </thead>
                    <tbody>
                    {isLoading ? (
                        <tr>
                            <td colSpan={7} className="px-4 py-4 text-center">
                                <Loader/>
                            </td>
                        </tr>
                    ) : reviews.length === 0 ? (
                        <tr>
                            <td colSpan={8} className="px-4 py-4 text-center text-gray-500">
                                Отзывы не найдены
                            </td>
                        </tr>
                    ) : (
                        reviews.map((review, index) => (
                            <tr key={index} className="border-t border-gray-200 hover:bg-gray-100">
                                <td className="px-2 sm:px-4 py-2 text-gray-500">{review.id}</td>
                                <td className="px-2 py-4 max-w-48 text-xs">
                                      <span className="line-clamp-3" title={review.text}>
                                            {review.text}
                                      </span>
                                </td>
                                <td className="px-2 sm:px-4 py-2">{review.rating}</td>
                                <td className="px-2 sm:px-4 py-2">
                                    <span
                                        className={`block w-full px-2 py-1 sm:py-2 rounded-xl font-medium ${getReviewToneTypeColor(review.tone)}`}
                                    >
                                        {getReviewToneTypesLabel(review.tone)}
                                    </span>
                                </td>
                                <td className="px-2 sm:px-4 py-2">
                                    <span
                                        className={`block w-full px-2 py-1 sm:py-2 rounded-xl font-medium ${getAuditReviewStatusColor(review.status)}`}
                                    >
                                        {getAuditReviewStatusLabel(review.status)}
                                    </span>
                                </td>
                                <td className="px-2 py-4 max-w-48 text-xs">
                                      <span className="line-clamp-3" title={review.officialAnswer}>
                                            {review.officialAnswer}
                                      </span>
                                </td>
                                <td className="px-2 sm:px-4 py-2 hidden sm:table-cell">{new Date(review.publishedAt).toLocaleString("ru-RU", {timeZone: 'Asia/Almaty'})}</td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>

            {!isLoading && <div className="mt-4 flex flex-row justify-between items-center">
                <Button
                    label="Назад"
                    onClick={handlePrevPage}
                    disabled={page === 1}
                    className="px-6 py-2 sm:px-10 mb-0 h-10 flex items-center"
                />
                <span
                    className="inline-flex items-center justify-center h-10 leading-none text-center">{page}/{totalPages}</span>
                <Button
                    label="Вперед"
                    onClick={handleNextPage}
                    disabled={page >= totalPages}
                    className="px-6 py-2 sm:px-10 mb-0 h-10 flex items-center"
                />
            </div>}
        </div>
    );

};

export default AuditReviewsPage;
