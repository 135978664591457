import React from 'react';

interface IconProps {
    className?: string;
}

const ReviewIcon: React.FC<IconProps> = ({className}) => {
    return (
        <svg fill="currentColor" className={className} version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 22.5098 20.459">
            <g>
                <rect height="20.459" opacity="0" width="22.5098" x="0" y="0"/>
                <path
                    d="M4.23828 20.459C5.55664 20.459 8.25195 19.1309 10.2344 17.7148C17.041 17.9004 22.1484 13.9941 22.1484 8.87695C22.1484 3.96484 17.2266 0 11.0742 0C4.92188 0 0 3.96484 0 8.87695C0 12.0801 2.05078 14.9219 5.13672 16.3477C4.69727 17.1973 3.87695 18.3496 3.4375 18.9258C2.91992 19.6094 3.23242 20.459 4.23828 20.459ZM5.26367 18.8379C5.18555 18.8672 5.15625 18.8086 5.20508 18.7402C5.75195 18.0664 6.5332 17.0508 6.86523 16.4258C7.13867 15.918 7.07031 15.4688 6.44531 15.1758C3.37891 13.75 1.62109 11.4746 1.62109 8.87695C1.62109 4.87305 5.81055 1.61133 11.0742 1.61133C16.3477 1.61133 20.5371 4.87305 20.5371 8.87695C20.5371 12.8711 16.3477 16.1328 11.0742 16.1328C10.8789 16.1328 10.5762 16.123 10.1855 16.1133C9.77539 16.1133 9.46289 16.2402 9.0918 16.5332C7.89062 17.4023 6.15234 18.4766 5.26367 18.8379Z"
                    fill-opacity="0.85"/>
            </g>
        </svg>
    );
};

export default ReviewIcon;
