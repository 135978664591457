import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Button from '../components/Button';
import {login as serviceLogin} from '../services/authService';
import useAuthStore from "../store/authStore";

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const authStore = useAuthStore();

    const handleSubmit = async () => {
        try {
            const authToken = await serviceLogin(username, password);
            authStore.setAccessToken(authToken)
            navigate('/campaigns');
        } catch (err) {
            setError('Неверный логин или пароль');
            console.log(err);
        }
    };

    return (
        <div className="p-8 max-w-md mx-auto">
            <h2 className="text-3xl font-bold mb-6">Авторизация</h2>
            <div className="space-y-4"> {/* Use form here */}
                {error && <p className="text-red-500">{error}</p>}
                <div>
                    <label className="block text-sm font-medium mb-2">Логин</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="w-full p-3 border rounded-xl focus:outline-none"
                        required
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium mb-2">Пароль</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-3 border rounded-xl focus:outline-none"
                        required
                    />
                </div>
                <Button
                    disabled={!username || !password}
                    label="Войти"
                    className="w-full py-3 bg-blue-600 text-white rounded-md"
                    onClick={handleSubmit}
                />
            </div>
        </div>
    );
};

export default LoginPage;
