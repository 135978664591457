import axiosInstance from '../utils/axiosConfig';
import {PaginationModel} from "../models/PaginationModel";
import {ProfileModel} from "../models/ProfileModel";
import {CreateProfileModel} from "../models/CreateProfileModel";

export async function fetchProfiles(
    page: number,
    limit: number,
    email: string | null,
    status: string | null
): Promise<PaginationModel<ProfileModel>> {
    const params: any = {
        page,
        limit,
    };

    if (status) {
        params["filter.status"] = status;
    }

    if (email) {
        params["filter.email"] = `$ilike:${email}`;
    }

    const response = await axiosInstance.get('/profiles', {params});

    return response.data;
}


export async function createProfile(profileData: CreateProfileModel[]): Promise<ProfileModel[]> {
    try {
        return (await axiosInstance.post("/profiles", profileData)).data as ProfileModel[];
    } catch (error) {
        console.error('Failed to create profile', error);
        throw new Error('Failed to create profile');
    }
}

export async function getProfile(
    profileId: number,
): Promise<ProfileModel> {
    const response = await axiosInstance.get(`/profiles/${profileId}`);

    return response.data;
}

export async function confirmProfile(
    profileId: number,
): Promise<void> {
    await axiosInstance.patch(`/profiles/${profileId}/confirm`);
}
