import React from 'react';

interface IconProps {
    className?: string;
}

const ReportIcon: React.FC<IconProps> = ({className}) => {
    return (
        <svg fill="currentColor"
             version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             className={className} viewBox="0 0 16.7871 20.9473">
            <g>
                <rect height="20.9473" opacity="0" width="16.7871" x="0" y="0"/>
                <path
                    d="M4.51172 5.6543L11.9238 5.6543C12.2656 5.6543 12.5195 5.39062 12.5195 5.04883C12.5195 4.7168 12.2656 4.45312 11.9238 4.45312L4.51172 4.45312C4.16016 4.45312 3.90625 4.7168 3.90625 5.04883C3.90625 5.39062 4.16016 5.6543 4.51172 5.6543ZM4.51172 9.0625L11.9238 9.0625C12.2656 9.0625 12.5195 8.79883 12.5195 8.45703C12.5195 8.125 12.2656 7.86133 11.9238 7.86133L4.51172 7.86133C4.16016 7.86133 3.90625 8.125 3.90625 8.45703C3.90625 8.79883 4.16016 9.0625 4.51172 9.0625ZM4.51172 12.4707L8.01758 12.4707C8.36914 12.4707 8.62305 12.2168 8.62305 11.8848C8.62305 11.5332 8.36914 11.2695 8.01758 11.2695L4.51172 11.2695C4.16016 11.2695 3.90625 11.5332 3.90625 11.8848C3.90625 12.2168 4.16016 12.4707 4.51172 12.4707ZM0 17.8809C0 19.9219 1.00586 20.9375 3.02734 20.9375L13.3984 20.9375C15.4199 20.9375 16.4258 19.9219 16.4258 17.8809L16.4258 3.06641C16.4258 1.03516 15.4199 0 13.3984 0L3.02734 0C1.00586 0 0 1.03516 0 3.06641ZM1.57227 17.8516L1.57227 3.0957C1.57227 2.11914 2.08984 1.57227 3.10547 1.57227L13.3203 1.57227C14.3359 1.57227 14.8535 2.11914 14.8535 3.0957L14.8535 17.8516C14.8535 18.8281 14.3359 19.3652 13.3203 19.3652L3.10547 19.3652C2.08984 19.3652 1.57227 18.8281 1.57227 17.8516Z"
                    fillOpacity="0.85"/>
            </g>
        </svg>
    );
};

export default ReportIcon;
