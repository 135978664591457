// src/App.tsx
import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import CreateCampaignPage from './pages/CreateCampaignPage';
import CampaignsPage from './pages/CampaignsPage';
import ReviewsPage from './pages/ReviewsPage';
import LoginPage from './pages/LoginPage';
import CampaignStatsPage from "./pages/CampaignsStatsPage";
import EditCampaignPage from "./pages/EditCampaignPage";
import ProfilesPage from "./pages/ProfilesPage";
import useAuthStore from "./store/authStore";
import PublishAttemptsPage from "./pages/ReviewReportPage";
import ProfileReportPage from "./pages/ProfileReportPage";
import CreateProfilePage from "./pages/CreateProfilePage";
import SideBar from "./components/SideBar";
import NotFoundPage from "./pages/NotFoundPage";
import AuditsPage from "./pages/AuditsPage";
import CreateAuditPage from "./pages/CreateAuditPage";
import AuditReviewsPage from "./pages/AuditReviewsPage";

const App = () => {
    const isAuthenticated = useAuthStore((state) => state.isAuthenticated());

    return (
        <Router>
            {isAuthenticated && <SideBar/>}
            <div className={'min-h-screen transition-all ml-20 md:m-0'}>
                <main className="px-4 py-8 md:p-8">
                    <Routes>
                        <Route path="/" element={<CampaignsPage/>}/>
                        <Route path="/create-audit" element={<CreateAuditPage/>}/>
                        <Route path="/audits" element={<AuditsPage/>}/>
                        <Route path="/audit/:id/reviews" element={<AuditReviewsPage/>}/>
                        <Route path="/create-campaign" element={<CreateCampaignPage/>}/>
                        <Route path="/campaigns" element={<CampaignsPage/>}/>
                        <Route path="/reviews" element={<ReviewsPage/>}/>
                        <Route path="/review/:id/report" element={<PublishAttemptsPage/>}/>
                        <Route path="/campaign/:id/stats" element={<CampaignStatsPage/>}/>
                        <Route path="/campaign/:id/edit" element={<EditCampaignPage/>}/>
                        <Route path="/profiles" element={<ProfilesPage/>}/>
                        <Route path="/profile/:id/report" element={<ProfileReportPage/>}/>
                        <Route path="/create-profile" element={<CreateProfilePage/>}/>
                        <Route path="/login" element={<LoginPage/>}/>
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </main>
            </div>
        </Router>
    );
};

export default App;
