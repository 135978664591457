import React, {useState} from 'react';
import TextArea from "../components/TextArea";
import Button from "../components/Button";
import {CreateProfileModel} from '../models/CreateProfileModel';
import {createProfile} from '../services/profileService';
import {useNavigate} from 'react-router-dom';
import Modal from "../components/Modal";
import {fetchProfileNames} from "../services/gptService";
import Loader from "../components/Loader";

const CreateProfilePage = () => {
    const [profilesData, setProfilesData] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isWarnModalOpen, setIsWarnModalOpen] = useState(false);
    const [failedEmails, setFailedEmails] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [profiles, setProfiles] = useState<CreateProfileModel[]>([]); // Состояние для редактирования профилей
    const [errors, setErrors] = useState({profilesData: ''});
    const navigate = useNavigate();

    const validateForm = () => {
        const newErrors = {profilesData: ''};

        if (!profilesData.trim()) {
            newErrors.profilesData = 'Данные профилей обязательны';
        } else {
            const profiles = profilesData.split('\n');
            const invalidProfiles = profiles.filter(profile => !validateProfile(profile));

            if (invalidProfiles.length > 0) {
                newErrors.profilesData = 'Некоторые профили имеют некорректный формат';
            }
        }

        setErrors(newErrors);
        return Object.values(newErrors).every(error => error === '');
    };

    const validateProfile = (profile: string): boolean => {
        const profilePattern = /^[^:]+:[^:]+:[^:]+$/;
        return profilePattern.test(profile);
    };

    const parseProfiles = (): CreateProfileModel[] => {
        return profilesData.split('\n').map(profile => {
            const [email, password, recoveryEmail] = profile.split(':');
            return {
                name: '',
                email,
                password,
                recoveryEmail,
            };
        });
    };

    const clearError = (field: keyof typeof errors) => {
        setErrors(prevErrors => ({...prevErrors, [field]: ''}));
    };

    const continueToNextStep = async () => {
        if (validateForm()) {
            setIsLoading(true)
            const profilesData = parseProfiles();

            const profileNames = await fetchProfileNames(profilesData.length);

            const updatedProfiles = profilesData.map((profile, index) => {
                if (profileNames[index]) {
                    profile.name = profileNames[index]
                        .trim()
                        .replace(/\s+/g, ' ');
                }
                return profile;
            });

            setProfiles(updatedProfiles);
            setIsLoading(false)
        }
    };

    // Создание профилей
    const createProfiles = async () => {
        try {
            setIsModalOpen(true)
        } catch (error) {
            console.error("Failed to create profile:", error);
        }
    };

    // Создание профилей
    const handleConfirmCreateProfiles = async () => {
        try {
            setIsModalOpen(false)
            setProfilesData('');
            const failedProfiles = await createProfile(profiles);
            if (failedProfiles.length > 0) {
                setFailedEmails((failedProfiles).map(element => element.email).join(', '));
                setIsWarnModalOpen(true)
            } else {
                navigate("/profiles");
            }
        } catch (error) {
            console.error("Failed to create profile:", error);
        }
    };

    // Создание профилей
    const handleProfileWarningModalConfirm = async () => {
        setIsWarnModalOpen(false)
        navigate("/profiles");
    };

    return (
        <div className="max-w-4xl mx-auto">
            <h1 className="text-3xl font-bold mb-6">Создание профилей</h1>

            <div className="space-y-6">
                {profiles.length === 0 ? (
                    <>
                        {/* Отображаем TextArea и кнопку "Продолжить" */}
                        <label className="block text-sm font-bold mb-2">Список профилей</label>
                        <TextArea
                            placeholder="email:password:recovery_email"
                            value={profilesData}
                            setValue={(value) => {
                                setProfilesData(value);
                                clearError('profilesData');
                            }}
                            error={errors.profilesData}
                        />
                        <Button
                            label={isLoading ? <Loader /> : "Продолжить"}
                            onClick={continueToNextStep}
                            disabled={Object.values(errors).some(error => error) || isLoading}
                        />
                    </>
                ) : (
                    <>
                        <label className="block text-sm font-bold mb-2">Имена профилей</label>
                        <div className="space-y-4">
                            {profiles.map((profile, index) => (
                                <div
                                    key={index}
                                    className="grid grid-cols-[auto_1fr] items-center gap-4"
                                >
                                    <p className="text-sm text-gray-700 w-60 truncate">{profile.email}</p>
                                    <input
                                        type="text"
                                        value={profile.name}
                                        onChange={(e) => {
                                            const updatedProfiles = [...profiles];
                                            updatedProfiles[index].name = e.target.value;
                                            setProfiles(updatedProfiles);
                                        }}
                                        className="border border-gray-300 rounded-lg p-2 w-full"
                                        placeholder="Введите имя"
                                    />
                                </div>
                            ))}
                        </div>
                        <Button label="Создать" onClick={createProfiles}/>
                    </>
                )}
            </div>

            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onAction={handleConfirmCreateProfiles}
                title="Подтверждение создания профилей"
                actionLabel="Подтвердить"
                description="Вы уверены, что хотите создать эти профили?"
            />

            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onAction={handleConfirmCreateProfiles}
                title="Подтверждение создания профилей"
                actionLabel="Подтвердить"
                description="Вы уверены, что хотите создать эти профили?"
            />

            <Modal
                isOpen={isWarnModalOpen}
                onClose={() => handleProfileWarningModalConfirm()}
                title="Проблема с созданияем профилей"
                cancelLabel="Ок"
                description={`Создание профилей началось. Однако данные профили уже существуют: ${failedEmails}.`}
            />
        </div>
    );
};

export default CreateProfilePage;
