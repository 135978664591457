import React from 'react';

interface IconProps {
    className?: string;
}

const ChevronLeftIcon: React.FC<IconProps> = ({className}) => {
    return (
        <svg fill="currentColor" className={className} version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 11.6406 16.9824">
            <g>
                <rect height="16.9824" opacity="0" width="11.6406" x="0" y="0"/>
                <path
                    d="M11.2793 16.0742C11.2793 15.8301 11.1914 15.6152 11.0156 15.4395L3.29102 7.88086L3.29102 9.08203L11.0156 1.5332C11.1914 1.36719 11.2793 1.14258 11.2793 0.898438C11.2793 0.380859 10.8887 0 10.3906 0C10.127 0 9.93164 0.0976562 9.74609 0.263672L2.00195 7.83203C1.82617 8.00781 1.71875 8.23242 1.71875 8.48633C1.71875 8.74023 1.82617 8.95508 2.01172 9.14062L9.74609 16.6992C9.92188 16.8848 10.127 16.9727 10.3809 16.9727C10.8887 16.9727 11.2793 16.582 11.2793 16.0742ZM1.74805 16.123L1.74805 0.869141C1.74805 0.361328 1.38672 0 0.869141 0C0.361328 0 0 0.361328 0 0.869141L0 16.123C0 16.6211 0.361328 16.9727 0.869141 16.9727C1.38672 16.9727 1.74805 16.6211 1.74805 16.123Z"
                    fillOpacity="0.85"/>
            </g>
        </svg>
    )
};

export default ChevronLeftIcon;
