import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {confirmProfile, getProfile} from "../services/profileService";
import {ProfileModel} from "../models/ProfileModel";
import {API_URL} from "../utils/config";
import {PhotoProvider, PhotoView} from 'react-photo-view';
import {ProfileStatus} from "../enums/ProfileStatus";
import Loader from "../components/Loader";
import {getProfileStatusColor, getProfileStatusLabel} from "../utils/enumUtils";

const ProfileReport = () => {
    const {id} = useParams<{ id: string }>();
    const [profile, setProfile] = useState<ProfileModel | null>(null);
    const [showButtons, setShowButtons] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchPublishAttempts = async () => {
            try {
                const fetchedProfile = await getProfile(Number(id));
                setProfile(fetchedProfile);
                setShowButtons(fetchedProfile.status === ProfileStatus.REGISTRATION_FAILED)
                setIsLoading(false)
            } catch (error) {
                console.error("Failed to fetch profile:", error);
            }
        };

        void fetchPublishAttempts();
    }, [id]);

    const handleConfirmProfile = async () => {
        try {
            setShowButtons(false)
            setProfile({...profile!, status: ProfileStatus.READY});
            await confirmProfile(Number(id));
        } catch (error) {
            console.error("Failed to confirm profile:", error);
        }
    };

    return (
        <div className="container mx-auto max-w-4xl">
            {isLoading || !profile ? (
                    <div className="flex items-center justify-center h-screen">
                        <Loader/>
                    </div>
                ) :
                <div className="layout-content-container flex flex-col max-w-[960px] flex-1 p-4 py-8">
                    <div className="flex flex-row sm:gap-4 mb-2 sm:mb-4 items-start sm:items-center">
                        <h2 className="text-base sm:text-2xl px-2 py-1 sm:py-2 font-bold">
                            Профиль
                        </h2>
                        <span
                            className={`block sm:mt-0 px-2 py-1 sm:py-2 rounded-xl font-medium ${getProfileStatusColor(profile?.status)}`}
                        >
                            {getProfileStatusLabel(profile?.status)}
                        </span>
                    </div>
                    <div
                        className="flex flex-col sm:flex-row items-stretch justify-between gap-4 rounded-xl bg-[#FFFFFF] p-4 shadow-[0_0_4px_rgba(0,0,0,0.1)]">
                        <div className="flex flex-1 flex-col gap-4">
                            <div className="flex flex-col gap-1">
                                <p className="text-[#141414] text-base sm:text-lg font-bold leading-tight">
                                    {profile?.email}
                                </p>
                                <p className="text-[#3E4D5B] text-sm sm:text-base font-normal leading-normal">
                                    {profile?.name}, {profile?.gologinId}
                                </p>
                            </div>
                            <div className="flex flex-wrap gap-2">
                                {showButtons && (
                                    <button
                                        onClick={handleConfirmProfile}
                                        className="flex min-w-[84px] max-w-full cursor-pointer items-center justify-center rounded-xl h-8 px-4 bg-green-50 text-[#141414] text-sm font-medium leading-normal"
                                    >
                                        <span className="truncate">Одобрить</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>

                    {profile?.registrationAttempts && profile?.registrationAttempts?.length > 0 &&
                        <h2 className="text-xl sm:text-2xl font-bold mt-4 sm:mt-8 mb-2 sm:mb-4">Попытки</h2>
                    }
                    <div className="flex flex-col gap-4">
                        {profile?.registrationAttempts?.map((attempt, index) => (
                            <div key={index}
                                 className="flex flex-col sm:flex-row items-start justify-between gap-4 rounded-xl bg-white p-4 shadow-md">
                                <p className="text-3xl flex items-center justify-center text-center px-4 font-mono w-12 sm:w-auto">
                                    {index + 1}
                                </p>
                                <div className="flex flex-1 flex-col gap-4">
                                    <div className="flex flex-col gap-1">
                                        <p className="text-gray-600 text-xs font-normal leading-normal font-mono break-words">{attempt.message}</p>
                                    </div>
                                    <div className="flex gap-3 flex-wrap">
                                        <div
                                            className="flex h-8 shrink-0 items-center justify-center gap-x-2 rounded-xl bg-[#F0F2F5] px-4">
                                            <p className="text-gray-600 text-xs font-medium leading-normal">
                                                {new Date(attempt.createdAt).toLocaleString("ru-RU", {timeZone: 'Asia/Almaty'})}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {attempt.screenshotFilename && (
                                    <PhotoProvider bannerVisible={false} maskOpacity={0.5}>
                                        <div className="relative bg-center bg-no-repeat aspect-video bg-cover rounded-xl flex-1 overflow-hidden">
                                            <PhotoView src={`${API_URL}/files/${attempt.screenshotFilename}`}>
                                                <img
                                                    src={`${API_URL}/files/${attempt.screenshotFilename}`}
                                                    alt=""
                                                    className="w-full h-full object-cover rounded-xl"
                                                />
                                            </PhotoView>
                                        </div>
                                    </PhotoProvider>
                                )}

                            </div>
                        ))}
                    </div>
                </div>}
        </div>
    );
};


export default ProfileReport;
