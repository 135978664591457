import React from 'react';

interface TextAreaProps {
    placeholder: string;
    value: string;
    setValue: (value: string) => void;
    error?: string;
    disabled?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({ placeholder, value, setValue, error, disabled }) => {
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
    };

    return (
        <div>
            <textarea
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                className={`w-full p-3 border rounded-xl bg-transparent focus:outline-none ${
                    error ? 'border-red-500' : 'border-gray-300'
                } ${disabled ? 'bg-gray-200 text-gray-500' : ''}`}
                rows={5}
                disabled={disabled}
            />
            {error && <p className="text-red-500 mt-1">{error}</p>}
        </div>
    );
};

export default TextArea;
