export enum ReviewStatus {
    SCHEDULED = 'SCHEDULED',
    MODERATING = 'MODERATING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    FAILED = 'FAILED',
    CANCELED = 'CANCELED',
    IN_QUEUE = 'IN_QUEUE',
    PROCESSING = 'PROCESSING',
}
