import axiosInstance from '../utils/axiosConfig';
import {AuditModel} from "../models/AuditModel";
import {PaginationModel} from "../models/PaginationModel";
import {AuditReviewModel} from "../models/AuditReviewModel";
import {CreateAuditModel} from "../models/CreateAuditModel";
import {AuditStatus} from "../enums/AuditStatus";
import {AuditReviewStatus} from "../enums/AuditReviewStatus";

export async function fetchAudits(page: number, limit: number, status: AuditStatus, auditName: string): Promise<PaginationModel<AuditModel>> {
    try {
        const params: Record<string, string | number> = {
            page,
            limit,
        };

        if (status) {
            params["filter.status"] = status;
        }

        if (auditName) {
            params["filter.name"] = `$ilike:${auditName}`;
        }

        const response = await axiosInstance.get('/audits', {params});

        return response.data;
    } catch (error) {
        console.error('Failed to fetch audits', error);
        throw new Error('Failed to fetch audits');
    }
}

export async function fetchAuditsReviews(auditId: string, page: number, limit: number, status: AuditReviewStatus): Promise<PaginationModel<AuditReviewModel>> {
    try {
        const params: Record<string, string | number> = {
            page,
            limit,
        };

        if (status) {
            params["filter.status"] = status;
        }

        const response = await axiosInstance.get(`/audits/${auditId}/reviews`, {params});

        return response.data;
    } catch (error) {
        console.error('Failed to fetch audit reviews', error);
        throw new Error('Failed to fetch audit reviews');
    }
}


export async function createAudit(auditData: CreateAuditModel): Promise<void> {
    try {
        await axiosInstance.post("/audits", auditData);
    } catch (error) {
        console.error('Failed to create audit', error);
        throw new Error('Failed to create audit');
    }
}

export async function downloadAuditReport(auditId: number, auditName: string): Promise<void> {
    try {
        const response = await axiosInstance.get(`/audits/${auditId}/report`, {
            responseType: 'blob',
        });

        const link = document.createElement('a');
        const url = window.URL.createObjectURL(response.data);
        link.href = url;
        link.download = `report (${auditName.toLowerCase()})`;
        link.click();

        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Failed to download audit report', error);
        throw new Error('Failed to download audit report');
    }
}

export async function downloadAuditReports(auditIds: number[]): Promise<void> {
    try {
        const response = await axiosInstance.get(`/audits/report`, {
            responseType: 'blob',
            params: {
                ids: auditIds.join(',')
            }
        });

        const link = document.createElement('a');
        const url = window.URL.createObjectURL(response.data);
        link.href = url;
        link.download = `report (${auditIds.join(' ').slice(0, 16)}${auditIds.join(' ').length > 16 ? '...' : ''})`;
        link.click();

        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Failed to download audit reports', error);
        throw new Error('Failed to download audit reports');
    }
}

