import React, {useEffect, useRef, useState} from 'react';
import {getReviews, retryReview} from '../services/reviewService';
import {ReviewModel} from "../models/ReviewModel";
import Button from "../components/Button";
import {ReviewStatus} from "../enums/ReviewStatus";
import Loader from "../components/Loader";
import {getReviewStatusColor, getReviewStatusLabel} from "../utils/enumUtils";
import RetryIcon from "../icons/Retry";
import {reviewsSocket} from "../utils/socket";
import {useNavigate, useSearchParams} from "react-router-dom";
import KebabIcon from "../icons/Kebab";
import ReportIcon from "../icons/Report";

const ReviewsPage = () => {
    const [reviews, setReviews] = useState<ReviewModel[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedReview, setSelectedReview] = useState<ReviewModel | null>(null);
    const dropDown = useRef<HTMLDivElement | null>(null)
    const navigate = useNavigate();
    const pageSize = 30;
    const page = parseInt(searchParams.get('page') || '1', 10);
    const selectedStatus = searchParams.get('status') as ReviewStatus || null;
    const campaignName = searchParams.get('campaign') || '';

    useEffect(() => {
        const loadReviews = async () => {
            try {
                setIsLoading(true)
                const pagination = await getReviews(page, pageSize, selectedStatus, campaignName);
                setReviews(pagination.data);
                setTotalPages(pagination.meta.totalPages);
                setTotalItems(pagination.meta.totalItems);
                setIsLoading(false)
            } catch (error) {
                console.error('Failed to load campaigns', error);
            }
        };

        void loadReviews();
    }, [page, selectedStatus, campaignName]);

    useEffect(() => {
        reviewsSocket.connect();

        reviewsSocket.on('connect_error', (error) => {
            console.error('Connection error:', error);
        });

        reviewsSocket.on('update', (updatedReview: ReviewModel) => {
            setReviews((prevReviews) =>
                prevReviews.map((review) =>
                    review.id === updatedReview.id
                        ? {...review, ...updatedReview}
                        : review
                )
            );
        });

        return () => {
            reviewsSocket.off('connect_error');
            reviewsSocket.off('update');
        };
    }, []);

    const closeDropDowns = (e: MouseEvent) => {
        const target = e.target as HTMLElement;

        if (dropdownOpen && !dropDown.current?.contains(target)) {
            setDropdownOpen(false)
        }
    }

    document.addEventListener('mousedown', closeDropDowns)

    const handleNextPage = () => {
        if (page < totalPages) {
            const newPage = (page + 1).toString();
            setSearchParams(prevParams => {
                const updatedParams = new URLSearchParams(prevParams);
                updatedParams.set('page', newPage);
                return updatedParams;
            });
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            const newPage = (page - 1).toString();
            setSearchParams(prevParams => {
                const updatedParams = new URLSearchParams(prevParams);
                updatedParams.set('page', newPage);
                return updatedParams;
            });
        }
    };

    const handleRetry = async (reviewId: number) => {
        if (selectedReview)
            toggleDropdown(selectedReview);
        await retryReview(reviewId);
    };

    const handleStatusChange = (status: ReviewStatus) => {
        const params: Record<string, string> = {
            page: "1",
        };

        if (status) {
            params.status = status;
        }
        if (searchParams.get('campaign')) {
            params.campaign = searchParams.get('campaign')!;
        }

        setSearchParams(params);
    };

    const handleCampaignNameChange = (campaignName: string) => {
        const params: Record<string, string> = {
            page: "1",
        };

        if (searchParams.get('status')) {
            params.status = searchParams.get('status')!;
        }
        if (campaignName) {
            params.campaign = campaignName;
        }

        setSearchParams(params);
    };

    const handleDropdownItemClick = (path: string) => {
        navigate(path);
        setDropdownOpen(false);
    };

    const toggleDropdown = (review: ReviewModel) => {
        setSelectedReview(review);
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <div className="container mx-auto ">
            <div className="flex flex-row items-center align-middle justify-between mb-4 sm:mb-6">
                <div className="flex flex-row gap-2 sm:gap-4 items-center">
                    <h1 className="text-2xl sm:text-4xl font-bold mb-0">Отзывы</h1>
                    <span
                        className={`text-sm sm:text-base block sm:mt-0 px-3 py-1 sm:py-2 rounded-xl font-medium bg-primary`}>{totalItems}</span>
                </div>
            </div>

            <div className="flex flex-row gap-2">
                {/* Dropdown Filter */}
                <div className="relative mb-4 w-full sm:w-fit">
                <span className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        fill="currentColor"
                        viewBox="0 0 256 256"
                    >
                        <path
                            d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"
                        ></path>
                    </svg>
                </span>
                    <select
                        className="w-full bg-primary appearance-none rounded-lg py-2 pl-4 pr-10 text-gray-700 shadow-sm focus:outline-none"
                        value={selectedStatus || ""}
                        onChange={(e) => handleStatusChange(e.target.value as ReviewStatus)}
                    >
                        <option value="">Все статусы</option>
                        {Object.values(ReviewStatus).map((status: ReviewStatus) => (
                            <option key={status} value={status}>{getReviewStatusLabel(status)}</option>))}
                    </select>
                </div>

                {/* Campaign Name Input Filter */}
                <div className="relative mb-4 w-full sm:w-fit">
                    <input
                        type="text"
                        className="w-full bg-primary rounded-lg py-2 pl-4 pr-10 text-gray-700 shadow-sm focus:outline-none"
                        placeholder="Кампания"
                        value={campaignName || ""}
                        onChange={(e) => handleCampaignNameChange(e.target.value)}
                    />
                </div>
            </div>

            <div className="overflow-x-auto">
                <div className="border border-gray-200 rounded-lg overflow-x-auto">
                    <table className="table-auto w-full text-center">
                        <thead>
                        {!isLoading && <tr className="border-b border-gray-200">
                            <th className="px-4 sm:px-4 py-5 font-medium text-gray-500">#</th>
                            <th className="px-2 py-4 font-medium">Кампания</th>
                            <th className="px-2 py-4 font-medium">Почта</th>
                            <th className="px-2 py-4 font-medium">Отзыв</th>
                            <th className="px-2 py-4 font-medium">Оценка</th>
                            <th className="px-2 py-4 font-medium">Статус</th>
                            <th className="px-2 py-4 font-medium">Дата публикации</th>
                        </tr>}
                        </thead>
                        <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan={7} className="px-4 py-4 text-center">
                                    <Loader/>
                                </td>
                            </tr>
                        ) : reviews.length === 0 ? (
                            <tr>
                                <td colSpan={7} className="px-2 py-4 text-center text-gray-500">
                                    Отзывы не найдены
                                </td>
                            </tr>
                        ) : (
                            reviews.map((review, index) => (
                                <tr key={index} className="border-t border-gray-200 hover:bg-gray-100">
                                    <td className="px-4 py-4 text-gray-500">{review.id}</td>
                                    <td className="px-2 py-4 max-w-48">
                                        <span className="line-clamp-2" title={review.campaign.name}>
                                            {review.campaign.name}
                                        </span>
                                    </td>
                                    <td className="px-2 py-4">{review.email}</td>
                                    <td className="px-2 py-4 max-w-48 text-xs">
                                      <span className="line-clamp-3" title={review.message}>
                                            {review.message}
                                      </span>
                                    </td>
                                    <td className="px-2 py-4">{review.rating}</td>
                                    <td className="px-2 py-4 relative">
                                        <div className="flex items-center justify-between">
                                                <span
                                                    className={`px-2 py-2 h-10 rounded-xl font-medium flex-grow text-center flex items-center justify-center ${getReviewStatusColor(review.status)}`}
                                                >
                                                    {getReviewStatusLabel(review.status)}
                                                </span>
                                            {review.status === ReviewStatus.FAILED && (
                                                <button
                                                    className="ml-2 w-10 h-10 px-2 py-2 flex items-center justify-center bg-red-100 hover:bg-red-200 text-white rounded-xl"
                                                    onClick={() => toggleDropdown(review)}
                                                    title="Retry"
                                                >
                                                    <KebabIcon className="w-4 h-4 text-black"/>
                                                </button>
                                            )}
                                        </div>

                                        {(dropdownOpen && selectedReview?.id === review.id) && (
                                            <div ref={dropDown}
                                                 className="absolute right-0 bottom-0 z-5 bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-32">
                                                <ul className="py-2 text-sm text-gray-700"
                                                    aria-labelledby="dropdownMenuIconButton">
                                                    <li className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                        onClick={() => handleDropdownItemClick(`/review/${review.id}/report`)}>
                                                        <ReportIcon className="mr-2 w-5 h-5"/>
                                                        <p className="text-gray-700 hover:text-gray-900">Репорт</p>
                                                    </li>
                                                    <li className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                        onClick={() => handleRetry(review.id)}>
                                                        <RetryIcon className="mr-2 w-5 h-5"/>
                                                        <p className="text-gray-700 hover:text-gray-900">Повтор</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </td>

                                    <td className="px-2 py-2">{new Date(review.scheduledTime)?.toLocaleString("ru-RU", {timeZone: 'Asia/Almaty'})}</td>
                                </tr>
                            ))
                        )}
                        </tbody>
                    </table>
                </div>
            </div>

            {!isLoading && <div className="mt-4 flex flex-row justify-between items-center">
                <Button
                    label="Назад"
                    onClick={handlePrevPage}
                    disabled={page === 1}
                    className="px-6 py-2 sm:px-10 mb-0 h-10 flex items-center"
                />
                <span
                    className="inline-flex items-center justify-center h-10 leading-none text-center">{page}/{totalPages}</span>
                <Button
                    label="Вперед"
                    onClick={handleNextPage}
                    disabled={page >= totalPages}
                    className="px-6 py-2 sm:px-10 mb-0 h-10 flex items-center"
                />
            </div>}

        </div>
    );
};

export default ReviewsPage;
