import axiosInstance from '../utils/axiosConfig';
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export async function login(username: string, password: string): Promise<string> {
    try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        const fingerprint = result.visitorId;

        const response = await axiosInstance.post('/auth/login', {username, password, fingerprint});

        const {accessToken} = response.data;

        return accessToken;
    } catch (error) {
        console.error('Login failed:', error);
        throw new Error('Invalid username or password');
    }
}

export async function refresh(fingerprint: string): Promise<string> {
    const response = await axiosInstance.post('/auth/refresh', {}, {headers: {fingerprint}});

    const {accessToken} = response.data;

    return accessToken;
}
