import React, {useEffect, useState} from 'react';

export interface Percentages {
    [key: number]: number;
}

interface RatingDistributionProps {
    initialPercentages?: Percentages;
    setPercentages: (percentages: Percentages) => void;
}

const RatingDistribution: React.FC<RatingDistributionProps> = ({ initialPercentages, setPercentages }) => {
    const [percentages, setLocalPercentages] = useState<Percentages>(() => {
        return initialPercentages || {
            5: 100,
            4: 0,
            3: 0,
            2: 0,
            1: 0,
        };
    });

    useEffect(() => {
        if (initialPercentages) {
            setLocalPercentages(initialPercentages);
        }
    }, [initialPercentages]);


    const handlePercentageChange = (stars: number, value: number) => {
        const newPercentages = { ...percentages };
        const delta = value - percentages[stars];

        newPercentages[stars] = value;

        if (delta > 0) {
            let remainingDelta = delta;
            let nextStars = stars - 1;

            let canAdjustDownwards = false;
            for (let i = stars - 1; i >= 1; i--) {
                if (newPercentages[i] > 0) {
                    canAdjustDownwards = true;
                    break;
                }
            }

            if (!canAdjustDownwards) {
                let topStars = 5;
                while (remainingDelta > 0 && topStars > 0) {
                    if (newPercentages[topStars] > 0) {
                        const deductAmount = Math.min(newPercentages[topStars], remainingDelta);
                        newPercentages[topStars] -= deductAmount;
                        remainingDelta -= deductAmount;
                    }
                    topStars--;
                }
            } else {
                while (remainingDelta > 0 && nextStars >= 1) {
                    if (newPercentages[nextStars] > 0) {
                        const deductAmount = Math.min(newPercentages[nextStars], remainingDelta);
                        newPercentages[nextStars] -= deductAmount;
                        remainingDelta -= deductAmount;
                    }
                    nextStars--;
                }
            }
        } else {
            let remainingDelta = -delta;
            let nextStars = stars - 1;

            while (remainingDelta > 0 && nextStars >= 1) {
                newPercentages[nextStars] += remainingDelta;
                remainingDelta = 0;
                nextStars--;
            }

            if (remainingDelta > 0) {
                let i = 5;
                while (remainingDelta > 0 && i >= 1) {
                    newPercentages[i] += remainingDelta;
                    remainingDelta = 0;
                    i--;
                }
            }
        }

        const totalSum = Object.values(newPercentages).reduce((sum, perc) => sum + perc, 0);
        if (totalSum !== 100) {
            let correction = 100 - totalSum;

            if (correction < 0) {
                let i = 1;
                while (correction < 0 && i <= 5) {
                    if (newPercentages[i] + correction >= 0) {
                        newPercentages[i] += correction;
                        correction = 0;
                    } else {
                        correction += newPercentages[i];
                        newPercentages[i] = 0;
                    }
                    i++;
                }
            }
        }

        setLocalPercentages(newPercentages);
        setPercentages(newPercentages);
    };


    return (
        <div>
            <h3 className="text-lg font-semibold">Распределение оценок</h3>
            <div className="space-y-5">
                {Object.keys(percentages).reverse().map((stars) => (
                    <RatingBar
                        key={stars}
                        stars={parseInt(stars)}
                        percentage={percentages[parseInt(stars)]}
                        onPercentageChange={handlePercentageChange}
                    />
                ))}
            </div>
        </div>
    );
};

interface RatingBarProps {
    stars: number;
    percentage: number;
    onPercentageChange: (stars: number, value: number) => void;
}


const RatingBar: React.FC<RatingBarProps> = ({ stars, percentage, onPercentageChange }) => {
    return (
        <div className="flex items-center space-x-2 mt-5">
            <span className="min-w-10 font-mono">{stars} ⭐</span>
            <input
                type="range"
                min="0"
                max="100"
                step="10"
                value={percentage}
                className="w-full h-1"
                onChange={(e) => onPercentageChange(stars, parseInt(e.target.value))}
            />
            <span className="min-w-10">{percentage}%</span>
        </div>
    );
};

export default RatingDistribution;
