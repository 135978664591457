import React from 'react';

interface IconProps {
    className?: string;
}

const ChevronRightIcon: React.FC<IconProps> = ({className}) => {
    return (
        <svg fill="currentColor" className={className} version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 11.6406 16.9824">
            <g>
                <rect height="16.9824" opacity="0" width="11.6406" x="0" y="0"/>
                <path
                    d="M0 16.0742C0 16.582 0.390625 16.9727 0.898438 16.9727C1.14258 16.9727 1.35742 16.8848 1.5332 16.6992L9.26758 9.14062C9.45312 8.95508 9.55078 8.74023 9.55078 8.48633C9.55078 8.23242 9.45312 8.00781 9.27734 7.83203L1.5332 0.263672C1.34766 0.0976562 1.14258 0 0.888672 0C0.390625 0 0 0.380859 0 0.898438C0 1.14258 0.0878906 1.36719 0.263672 1.5332L7.98828 9.08203L7.98828 7.88086L0.263672 15.4395C0.0878906 15.6152 0 15.8301 0 16.0742ZM9.53125 16.123C9.53125 16.6211 9.89258 16.9727 10.4004 16.9727C10.9082 16.9727 11.2793 16.6211 11.2793 16.123L11.2793 0.869141C11.2793 0.361328 10.9082 0 10.4004 0C9.89258 0 9.53125 0.361328 9.53125 0.869141Z"
                    fillOpacity="0.85"/>
            </g>
        </svg>

    )
};

export default ChevronRightIcon;
