import React, {useEffect, useState} from 'react';
import {fetchProfiles} from '../services/profileService';
import {ProfileModel} from "../models/ProfileModel";
import Button from "../components/Button";
import Loader from "../components/Loader";
import {getProfileStatusColor, getProfileStatusLabel} from "../utils/enumUtils";
import {useNavigate, useSearchParams} from "react-router-dom";
import {profilesSocket} from "../utils/socket";
import {ProfileStatus} from "../enums/ProfileStatus";
import ReportIcon from "../icons/Report";

const ProfilesPage = () => {
    const [profiles, setProfiles] = useState<ProfileModel[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const pageSize = 30;
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const page = parseInt(searchParams.get('page') || '1', 10);
    const email = searchParams.get('email') || '';
    const status = searchParams.get('status') as ProfileStatus || null;

    useEffect(() => {
        const loadProfiles = async () => {
            try {
                setIsLoading(true)
                const pagination = await fetchProfiles(page, pageSize, email, status);
                setProfiles(pagination.data);
                setTotalPages(pagination.meta.totalPages);
                setTotalItems(pagination.meta.totalItems);
                setIsLoading(false)
            } catch (error) {
                console.error('Failed to load profiles', error);
            }
        };

        void loadProfiles();
    }, [page, email, status]);

    useEffect(() => {
        profilesSocket.connect();

        profilesSocket.on('connect_error', (error) => {
            console.error('Connection error: ', error);
        });

        profilesSocket.on('update', (updatedProfile: ProfileModel) => {
            setProfiles((prevProfiles) =>
                prevProfiles.map((profile) =>
                    profile.id === updatedProfile.id
                        ? {...profile, ...updatedProfile}
                        : profile
                )
            );
        });

        profilesSocket.on('create', (createdProfile: ProfileModel) => {
            setProfiles((prevProfiles) => [createdProfile, ...prevProfiles]);
        });

        return () => {
            profilesSocket.off('connect_error');
            profilesSocket.off('update');
            profilesSocket.off('create');
        };
    }, []);

    const navigateToReport = (profileId: number) => {
        navigate(`/profile/${profileId}/report`);
    }

    const handleNextPage = () => {
        if (page < totalPages) {
            const newPage = (page + 1).toString();
            setSearchParams(prevParams => {
                const updatedParams = new URLSearchParams(prevParams);
                updatedParams.set('page', newPage);
                return updatedParams;
            });
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            const newPage = (page - 1).toString();
            setSearchParams(prevParams => {
                const updatedParams = new URLSearchParams(prevParams);
                updatedParams.set('page', newPage);
                return updatedParams;
            });
        }
    };


    const handleEmailChange = (email: string) => {
        const params: Record<string, string> = {
            page: "1",
        };

        if (searchParams.get('status')) {
            params.status = searchParams.get('status')!;
        }

        if (email) {
            params.email = email;
        }

        setSearchParams(params);
    };

    const handleStatusChange = (status: ProfileStatus) => {
        const params: Record<string, string> = {
            page: "1",
        };

        if (status) {
            params.status = status;
        }

        if (searchParams.get('email')) {
            params.email = searchParams.get('email')!;
        }

        setSearchParams(params);
    };

    return (
        <div className="container mx-auto">
            <div className="flex flex-row items-center align-middle justify-between mb-4 sm:mb-6">
                <div className="flex flex-row gap-2 sm:gap-4 items-center">
                    <h1 className="text-2xl sm:text-4xl font-bold mb-0">Профили</h1>
                    <span
                        className={`text-sm sm:text-base block sm:mt-0 px-3 py-1 sm:py-2 rounded-xl font-medium bg-primary`}>{totalItems}</span>
                </div>
                <Button
                    label="Создать"
                    onClick={() => navigate('/create-profile')}
                    className="px-6 py-2 sm:px-10"
                />
            </div>

            <div className="flex flex-row gap-2">
                {/* Dropdown Filter */}
                <div className="relative mb-4 w-full sm:w-fit">
                <span className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20px"
                        height="20px"
                        fill="currentColor"
                        viewBox="0 0 256 256"
                    >
                        <path
                            d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"
                        ></path>
                    </svg>
                </span>
                    <select
                        className="w-full bg-primary appearance-none rounded-lg py-2 pl-4 pr-10 text-gray-700 shadow-sm focus:outline-none"
                        value={status || ""}
                        onChange={(e) => handleStatusChange(e.target.value as ProfileStatus)}
                    >
                        <option value="">Все статусы</option>
                        {Object.values(ProfileStatus).map((status: ProfileStatus) => (
                            <option key={status} value={status}>{getProfileStatusLabel(status)}</option>))}
                    </select>
                </div>

                {/* Campaign Name Input Filter */}
                <div className="relative mb-4 w-full sm:w-fit">
                    <input
                        type="text"
                        className="bg-primary rounded-lg py-2 pl-4 text-gray-700 shadow-sm focus:outline-none"
                        placeholder="Почта"
                        value={email || ""}
                        onChange={(e) => handleEmailChange(e.target.value)}
                    />
                </div>
            </div>

            <div className="overflow-x-auto">
                <div className="border border-gray-200 rounded-lg overflow-x-auto">
                    <table className="table-auto w-full text-center">
                        <thead>
                        {!isLoading && <tr className="border-b border-gray-200">
                            <th className="px-4 py-5 font-medium text-gray-500">#</th>
                            <th className="px-2 py-4 font-medium">Почта</th>
                            <th className="px-2 py-4 font-medium">Имя</th>
                            <th className="px-2 py-4 font-medium">Статус</th>
                            <th className="px-2 py-4 font-medium">Gologin ID</th>
                            <th className="px-2 py-4 font-medium">Дата создания</th>
                        </tr>}
                        </thead>
                        <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan={7} className="px-4 py-4 text-center">
                                    <Loader/>
                                </td>
                            </tr>
                        ) : profiles.length === 0 ? (
                            <tr>
                                <td colSpan={7} className="px-2 py-4 text-center text-gray-500">
                                    Профили не найдены
                                </td>
                            </tr>
                        ) : (
                            profiles.map((profile, index) => (
                                <tr key={index} className="border-t border-gray-200 hover:bg-gray-100">
                                    <td className="px-4 sm:px-4 py-2 text-gray-500">{profile.id}</td>
                                    <td className="px-2 py-4">{profile.email}</td>
                                    <td className="px-2 py-4">{profile.name}</td>
                                    <td className="px-2 py-4 relative">
                                        <div className="flex items-center justify-between">
                                                <span
                                                    className={`px-2 py-2 h-10 rounded-xl font-medium flex-grow text-center flex items-center text-nowrap justify-center ${getProfileStatusColor(profile.status)}`}
                                                >
                                                    {getProfileStatusLabel(profile.status)}
                                                </span>
                                            {(profile.status === ProfileStatus.REGISTRATION_FAILED || profile.status === ProfileStatus.FAILED) && (
                                                <button
                                                    className="ml-2 w-10 h-10 px-2 py-2 flex items-center justify-center bg-red-100 hover:bg-red-200 text-white rounded-xl"
                                                    onClick={() => navigateToReport(profile.id)}
                                                    title="Report"
                                                >
                                                    <ReportIcon className="text-black w-5 h-5"/>
                                                </button>
                                            )}
                                        </div>
                                    </td>
                                    <td className="px-2 py-4">{profile.gologinId}</td>
                                    <td className="px-2 py-2">{new Date(profile.createdAt)?.toLocaleString("ru-RU", {timeZone: 'Asia/Almaty'})}</td>
                                </tr>
                            ))
                        )}
                        </tbody>
                    </table>
                </div>
            </div>

            {!isLoading && <div className="mt-4 flex flex-row justify-between items-center">
                <Button
                    label="Назад"
                    onClick={handlePrevPage}
                    disabled={page === 1}
                    className="px-6 py-2 sm:px-10 mb-0 h-10 flex items-center"
                />
                <span
                    className="inline-flex items-center justify-center h-10 leading-none text-center">{page}/{totalPages}</span>
                <Button
                    label="Вперед"
                    onClick={handleNextPage}
                    disabled={page >= totalPages}
                    className="px-6 py-2 sm:px-10 mb-0 h-10 flex items-center"
                />
            </div>}
        </div>
    );
};

export default ProfilesPage;
