import React from 'react';

interface IconProps {
    className?: string;
}

const MessageIcon: React.FC<IconProps> = ({className}) => {
    return (
        <svg fill="currentColor" className={className} version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 22.5098 20.3613">
            <g>
                <rect height="20.3613" opacity="0" width="22.5098" x="0" y="0"/>
                <path
                    d="M3.86719 20.3613C5.03906 20.3613 7.95898 19.082 9.61914 17.8906C9.78516 17.7734 9.92188 17.7246 10.0586 17.7246C10.1855 17.7344 10.3125 17.7441 10.4395 17.7441C17.4805 17.7441 22.1484 13.7402 22.1484 8.87695C22.1484 3.96484 17.2266 0 11.0742 0C4.92188 0 0 3.96484 0 8.87695C0 12.002 1.92383 14.7461 5.05859 16.4258C5.20508 16.5039 5.25391 16.6504 5.17578 16.7969C4.62891 17.6953 3.65234 18.7695 3.28125 19.2578C2.90039 19.7461 3.11523 20.3613 3.86719 20.3613Z"
                    fillOpacity="0.85"/>
            </g>
        </svg>
    );
};

export default MessageIcon;
