import React from 'react';

const NotFoundPage = () => {
    return (
        <div className="p-8 max-w-md mx-auto flex justify-center items-center h-screen">
            <img
                src="not-found.svg"
                alt="Page Not Found"
                className="object-contain max-w-full max-h-full"
            />
        </div>
    );
};

export default NotFoundPage;
