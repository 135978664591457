import React from 'react';

interface IconProps {
    className?: string;
}

const EditIcon: React.FC<IconProps> = ({className}) => {
    return (
        <svg className={className}
             fill="currentColor" version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 23.6475 23.3041">
            <g>
                <rect height="23.3041" opacity="0" width="23.6475" x="0"
                      y="0"/>
                <path
                    d="M15.5591 4.88935L6.08643 4.88935C5.10986 4.88935 4.56299 5.41669 4.56299 6.43232L4.56299 17.5163C4.56299 18.5319 5.10986 19.0495 6.08643 19.0495L17.2095 19.0495C18.186 19.0495 18.7231 18.5319 18.7231 17.5163L18.7231 8.12957L20.2954 6.55445L20.2954 17.5944C20.2954 19.6159 19.27 20.6218 17.229 20.6218L6.05713 20.6218C4.02588 20.6218 2.99072 19.6159 2.99072 17.5944L2.99072 6.34443C2.99072 4.33271 4.02588 3.31708 6.05713 3.31708L17.1313 3.31708Z"
                    fillOpacity="0.85"/>
                <path
                    d="M9.61182 14.2936L11.5161 13.4636L20.6372 4.35224L19.2993 3.03388L10.188 12.1452L9.30908 13.9811C9.23096 14.1472 9.42627 14.3718 9.61182 14.2936ZM21.3599 3.63935L22.063 2.91669C22.395 2.56513 22.395 2.09638 22.063 1.77412L21.8384 1.53974C21.5356 1.23701 21.0571 1.27607 20.7349 1.58857L20.022 2.29169Z"
                    fillOpacity="0.85"/>
            </g>
        </svg>
    );
};

export default EditIcon;
