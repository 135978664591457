import React from 'react';

interface TextInputProps {
    label: string;
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    error?: string;
    disabled?: boolean;
}

const TextInput: React.FC<TextInputProps> = ({ label, value, setValue, error , disabled}) => {
    return (
        <div>
            <input
                type="text"
                value={value}
                placeholder={label}
                onChange={(e) => setValue(e.target.value)}
                disabled={disabled}
                className={`w-full p-3 border rounded-xl bg-transparent focus:outline-none ${
                    error ? 'border-red-500' : 'border-gray-300'
                } ${
                    disabled ? 'bg-gray-200 text-gray-500' : ''
                }`}
            />
            {error && <p className="text-red-500 mt-1">{error}</p>}
        </div>
    );
};

export default TextInput;
