import React from 'react';

interface IconProps {
    className?: string;
}

const RetryIcon: React.FC<IconProps> = ({className}) => {
    return (
        <svg fill="currentColor"
             version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 20.2832 23.7233"
             className={className}>
            <g>
                <rect height="23.7233" opacity="0" width="20.2832" x="0" y="0"/>
                <path
                    d="M9.96094 21.8177C15.459 21.8177 19.9219 17.3548 19.9219 11.8568C19.9219 8.50718 18.291 5.60679 15.7715 3.79038C15.3418 3.45835 14.7852 3.57554 14.541 3.96616C14.2969 4.37632 14.4238 4.8353 14.8242 5.13804C16.8945 6.62241 18.2617 9.07358 18.2617 11.8568C18.2617 16.4466 14.5508 20.1576 9.96094 20.1576C5.37109 20.1576 1.66016 16.4466 1.66016 11.8568C1.66016 7.89194 4.4043 4.62046 8.07617 3.77085L8.07617 5.1771C8.07617 5.87046 8.55469 6.04624 9.0918 5.67515L12.207 3.48765C12.6465 3.17515 12.6562 2.7064 12.207 2.38413L9.10156 0.19663C8.55469-0.184229 8.07617-0.00844799 8.07617 0.694677L8.07617 2.0814C3.50586 2.94077 0 6.98374 0 11.8568C0 17.3548 4.46289 21.8177 9.96094 21.8177Z"
                    fillOpacity="0.85"/>
            </g>
        </svg>
    );
};

export default RetryIcon;
