import React from 'react';

interface TimeInputProps {
    value: string;
    setValue: (value: string) => void;
    error?: string;
    disabled?: boolean;
}

const TimeInput: React.FC<TimeInputProps> = ({ value, setValue, error, disabled }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    return (
        <div>
            <input
                type="time"
                value={value}
                onChange={handleChange} // Handle input changes
                className={`w-full p-3 border rounded-xl bg-transparent focus:outline-none ${
                    error ? 'border-red-500' : 'border-gray-300'
                } ${
                    disabled ? 'bg-gray-200 text-gray-500' : ''
                }`}
                disabled={disabled}
            />
            {error && <p className="text-red-500 mt-1">{error}</p>}
        </div>
    );
};

export default TimeInput;
