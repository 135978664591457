import axios from 'axios';
import { refresh } from "../services/authService";
import useAuthStore from "../store/authStore";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { API_URL } from "./config";

const axiosInstance = axios.create({
    baseURL: API_URL,
    timeout: 60000,
    withCredentials: true
});

let isRefreshing = false;

axiosInstance.interceptors.request.use(
    async (config) => {
        const authStore = useAuthStore.getState();

        if (!config.url?.startsWith('/auth') && !authStore.isAuthenticated()) {
            if (authStore.accessTokenExpiresAt && authStore.accessTokenExpiresAt <= new Date()) {
                if (!isRefreshing) {
                    isRefreshing = true;
                    try {
                        await refreshAccessToken();
                    } catch (error) {
                        window.location.href = '/login';
                    } finally {
                        isRefreshing = false;
                    }
                } else {
                    return new Promise((resolve) => {
                        const interval = setInterval(() => {
                            if (!isRefreshing) {
                                clearInterval(interval);
                                resolve(config);
                            }
                        }, 100);
                    });
                }
            }
        }

        const refreshedAuthStore = useAuthStore.getState();
        const token = refreshedAuthStore.accessToken;
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

const refreshAccessToken = async (): Promise<void> => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const fingerprint = result.visitorId;
    const { setAccessToken } = useAuthStore.getState();

    try {
        const newAccessToken = await refresh(fingerprint);
        setAccessToken(newAccessToken);
    } catch (error) {
        console.error('Error refreshing token', error);
        throw error;
    }
};

export default axiosInstance;
