import React from 'react';

interface IconProps {
    className?: string;
}

const Megaphone: React.FC<IconProps> = ({className}) => {
    return (
        <svg fill="currentColor" className={className} version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 22.1094 20.8496">
            <g>
                <rect height="20.8496" opacity="0" width="22.1094" x="0" y="0"/>
                <path
                    d="M4.0918 14.2285L6.19141 14.2285L6.19141 4.89258L4.0918 4.89258C1.42578 4.89258 0 6.2793 0 8.84766L0 10.2734C0 12.832 1.42578 14.2285 4.0918 14.2285ZM7.5293 14.2383C10.293 14.541 13.75 15.3516 16.4355 16.6699C16.4355 16.5723 16.4355 16.4648 16.4355 16.3672L16.4355 2.7832C16.4355 2.66602 16.4355 2.56836 16.4355 2.45117C13.7207 3.7793 10.0488 4.61914 7.5293 4.89258ZM19.7559 19.0527C20.9473 19.0527 21.748 18.2422 21.748 17.0703L21.748 1.99219C21.748 0.810547 20.9473 0 19.7559 0C18.584 0 17.7734 0.810547 17.7734 1.99219L17.7734 17.0703C17.7734 18.2422 18.584 19.0527 19.7559 19.0527ZM7.97852 20.8398C9.18945 20.8398 10.0781 19.9902 9.76562 18.4863L9.21875 15.8594C8.47656 15.7324 7.37305 15.6445 4.90234 15.5762C4.72656 15.5762 4.42383 15.5566 4.11133 15.5566L5.75195 19.1406C6.24023 20.2051 6.8457 20.8398 7.97852 20.8398Z"
                    fillOpacity="0.85"/>
            </g>
        </svg>
    );
};

export default Megaphone;
