import React from 'react';

interface IconProps {
    className?: string;
}

const StatsIcon: React.FC<IconProps> = ({className}) => {
    return (
        <svg
            className={className} version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 25.1758 18.4082"
            fill="currentColor">
            <g>
                <rect height="18.4082" opacity="0" width="25.1758" x="0"
                      y="0"/>
                <path
                    d="M19.8633 18.3887L22.5 18.3887C24.0234 18.3887 24.8145 17.6562 24.8145 16.2109L24.8145 2.1875C24.8145 0.742188 24.0234 0 22.5 0L19.8633 0C18.3496 0 17.5684 0.742188 17.5684 2.1875L17.5684 16.2109C17.5684 17.6562 18.3496 18.3887 19.8633 18.3887ZM20.127 16.9141C19.3848 16.9141 19.1211 16.6602 19.1211 15.9277L19.1211 2.4707C19.1211 1.73828 19.3848 1.47461 20.127 1.47461L22.2461 1.47461C22.998 1.47461 23.2617 1.73828 23.2617 2.4707L23.2617 15.9277C23.2617 16.6602 22.998 16.9141 22.2461 16.9141Z"
                    fillOpacity="0.85"/>
                <path
                    d="M11.084 18.3887L13.7207 18.3887C15.2441 18.3887 16.0254 17.6562 16.0254 16.2109L16.0254 5.03906C16.0254 3.59375 15.2441 2.85156 13.7207 2.85156L11.084 2.85156C9.56055 2.85156 8.7793 3.59375 8.7793 5.03906L8.7793 16.2109C8.7793 17.6562 9.56055 18.3887 11.084 18.3887ZM11.3477 16.9141C10.5957 16.9141 10.332 16.6602 10.332 15.9277L10.332 5.32227C10.332 4.58984 10.5957 4.32617 11.3477 4.32617L13.4668 4.32617C14.2188 4.32617 14.4727 4.58984 14.4727 5.32227L14.4727 15.9277C14.4727 16.6602 14.2188 16.9141 13.4668 16.9141Z"
                    fillOpacity="0.85"/>
                <path
                    d="M2.30469 18.3887L4.93164 18.3887C6.45508 18.3887 7.24609 17.6562 7.24609 16.2109L7.24609 7.88086C7.24609 6.43555 6.45508 5.69336 4.93164 5.69336L2.30469 5.69336C0.78125 5.69336 0 6.43555 0 7.88086L0 16.2109C0 17.6562 0.78125 18.3887 2.30469 18.3887ZM2.55859 16.9141C1.81641 16.9141 1.55273 16.6602 1.55273 15.9277L1.55273 8.16406C1.55273 7.42188 1.81641 7.16797 2.55859 7.16797L4.67773 7.16797C5.42969 7.16797 5.69336 7.42188 5.69336 8.16406L5.69336 15.9277C5.69336 16.6602 5.42969 16.9141 4.67773 16.9141Z"
                    fillOpacity="0.85"/>
            </g>
        </svg>
    );
};

export default StatsIcon;
