import React, {useState} from 'react';
import TextInput from "../components/TextInput";
import TimeInput from "../components/TimeInput";
import TextArea from "../components/TextArea";
import RatingDistribution, {Percentages} from "../components/RatingDistribution";
import Calendar from "../components/Calendar";
import Button from "../components/Button";
import {CreateCampaignModel} from '../models/CreateCampaignModel';
import {createCampaign} from '../services/campaignService';
import {useNavigate} from 'react-router-dom';
import {fetchReviewFromGpt} from '../services/gptService';
import {CampaignReviewRatingModel} from "../models/CampaignReviewRatingModel";
import Modal from "../components/Modal";

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const CreateCampaignPage = () => {
    const [campaignName, setCampaignName] = useState('');
    const [mapUrl, setMapUrl] = useState('');
    const [reviewsPerDay, setReviewsPerDay] = useState('');
    const [reviewPeriod, setReviewPeriod] = useState<Value>(null);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [gptPrompt, setGptPrompt] = useState('');
    const [ratings, setRatings] = useState<CampaignReviewRatingModel[]>([{rating: 5, percent: 1.0}]);
    const [gptLoading, setGptLoading] = useState(false);
    const [generatedReview, setGeneratedReview] = useState('');
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const formatToLocalDate = (date: Date) => {
        return date.getFullYear() + '-' +
            String(date.getMonth() + 1).padStart(2, '0') + '-' +
            String(date.getDate()).padStart(2, '0');
    };

    const handleCreateCampaign = () => {
        if (validateForm()) {
            setIsModalOpen(true);
        }
    };

    const [errors, setErrors] = useState({
        campaignName: '',
        mapUrl: '',
        reviewsPerDay: '',
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        gptPrompt: ''
    });

    const validateForm = () => {
        const newErrors = {
            campaignName: '',
            mapUrl: '',
            reviewsPerDay: '',
            startDate: '',
            endDate: '',
            startTime: '',
            endTime: '',
            gptPrompt: ''
        };

        if (!campaignName.trim()) {
            newErrors.campaignName = 'Название кампании обязательно';
        }

        if (!mapUrl.trim()) {
            newErrors.mapUrl = '2GIS ссылка обязательна';
        } else if (!/\/firm\/\d+/.test(mapUrl)) {
            newErrors.mapUrl = 'Неверный формат URL (нужна полная ссылка)';
        }


        if (!reviewsPerDay.trim()) {
            newErrors.reviewsPerDay = 'Количество отзывов в день обязательно';
        } else if (isNaN(+reviewsPerDay) || +reviewsPerDay <= 0) {
            newErrors.reviewsPerDay = 'Количество отзывов должна быть больше нуля';
        }

        if (!reviewPeriod || !Array.isArray(reviewPeriod)) {
            newErrors.startDate = 'Период обязателен';
        } else {
            const [startDate, endDate] = reviewPeriod as [ValuePiece, ValuePiece];
            if (!startDate) {
                newErrors.startDate = 'Дата начала обязательна';
            }
            if (!endDate) {
                newErrors.endDate = 'Дата окончания обязательня';
            }
        }

        if (!startTime.trim()) {
            newErrors.startTime = 'Время начала обязательно';
        }

        if (!endTime.trim()) {
            newErrors.endTime = 'Время окончания обязательно';
        }

        if (!gptPrompt.trim()) {
            newErrors.gptPrompt = 'GPT промпт обязателен';
        }

        setErrors(newErrors);

        return Object.values(newErrors).every(error => error === '');
    };

    const clearError = (field: keyof typeof errors) => {
        setErrors(prevErrors => ({...prevErrors, [field]: ''}));
    };

    const handleConfirmCreation = async () => {
        if (validateForm()) {
            const [periodStart, periodEnd] = reviewPeriod as [ValuePiece, ValuePiece];

            const campaignData: CreateCampaignModel = {
                name: campaignName,
                twoGisUrl: mapUrl,
                periodStart: formatToLocalDate(periodStart!),
                periodEnd: formatToLocalDate(periodEnd!),
                dailyStartTime: startTime,
                dailyEndTime: endTime,
                maxReviewsPerDay: Number(reviewsPerDay),
                gptPrompt,
                ratings
            };

            try {
                await createCampaign(campaignData);

                setCampaignName('');
                setMapUrl('');
                setReviewsPerDay('');
                setReviewPeriod([null, null]);
                setStartTime('');
                setEndTime('');
                setGptPrompt('');
                setGeneratedReview('');
                setErrors({
                    campaignName: '',
                    mapUrl: '',
                    reviewsPerDay: '',
                    startDate: '',
                    endDate: '',
                    startTime: '',
                    endTime: '',
                    gptPrompt: ''
                });

                navigate("/campaigns")
            } catch (error) {
                console.error("Failed to create campaign:", error);
            }
        }
    };

    const handleGenerateReview = async () => {
        if (gptPrompt) {
            try {
                setGptLoading(true);
                const review = await fetchReviewFromGpt(gptPrompt)
                setGeneratedReview(review);
            } catch (error) {
                console.error("Failed to generate review:", error);
            } finally {
                setGptLoading(false);
            }
        }
    };

    const setPercentages = (percentages: Percentages) => {
        const ratings = Object.entries(percentages).map(([key, percentage]) => ({
            rating: Number(key),
            percent: percentage / 100.0
        })) as CampaignReviewRatingModel[];

        setRatings(ratings);
    };

    return (
        <div className="max-w-4xl mx-auto">
            <h1 className="text-3xl font-bold mb-6">Создание кампании</h1>

            <div className="space-y-6">
                {/* Campaign Name Input */}
                <TextInput
                    label="Название"
                    value={campaignName}
                    setValue={(value) => {
                        setCampaignName(value);
                        clearError('campaignName');
                    }}
                    error={errors.campaignName}
                />

                {/* 2GIS Map URL Input */}
                <TextInput
                    label="2GIS ссылка"
                    value={mapUrl}
                    setValue={(value) => {
                        setMapUrl(value);
                        clearError('mapUrl');
                    }}
                    error={errors.mapUrl}
                />

                {/* Reviews per day Input */}
                <TextInput
                    label="Количество отзывов в день"
                    value={reviewsPerDay}
                    setValue={(value) => {
                        setReviewsPerDay(value);
                        clearError('reviewsPerDay');
                    }}
                    error={errors.reviewsPerDay}
                />

                {/* Calendar Component for review period */}
                <div>
                    <h3 className="text-lg font-semibold">Период</h3>
                    <Calendar
                        selectedDate={reviewPeriod}
                        setSelectedDate={(value) => {
                            setReviewPeriod(value);
                            clearError('startDate');
                            clearError('endDate');
                        }}
                        label="Период"
                        error={errors.startDate || errors.endDate}
                    />
                </div>

                {/* Time Inputs */}
                <div className="flex space-x-4">
                    <div className="flex flex-col">
                        <label className="block text-sm font-bold mb-2">Время начала</label>
                        <TimeInput
                            value={startTime}
                            setValue={(value) => {
                                setStartTime(value);
                                clearError('startTime');
                            }}
                            error={errors.startTime}
                        />
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-sm font-bold mb-2">Время окончания</label>
                        <TimeInput
                            value={endTime}
                            setValue={(value) => {
                                setEndTime(value);
                                clearError('endTime');
                            }}
                            error={errors.endTime}
                        />
                    </div>
                </div>

                <section>
                    <div className="flex space-x-4 w-full">
                        <div className="w-1/2">
                            <label className="block text-sm font-bold mb-2">GPT промпт</label>
                            <TextArea
                                placeholder="Введите свой GPT промпт..."
                                value={gptPrompt}
                                setValue={(value) => {
                                    setGptPrompt(value);
                                    clearError('gptPrompt');
                                }}
                                error={errors.gptPrompt}
                            />
                        </div>
                        <div className="w-1/2">
                            <label className="block text-sm font-bold mb-2">Сгенерированный отзыв</label>
                            <p className="whitespace-normal break-words text-sm text-gray-500">
                                {generatedReview}
                            </p>
                        </div>
                    </div>
                    <Button
                        label={gptLoading ? "Загрузка..." : "Сгенерировать"}
                        disabled={!gptPrompt || gptLoading}
                        onClick={handleGenerateReview}
                        className="mt-0"
                    />
                </section>

                {/* Rating Distribution */}
                <RatingDistribution setPercentages={setPercentages}/>

                {/* Create Campaign Button */}
                <Button
                    label="Создать"
                    onClick={handleCreateCampaign}
                    disabled={Object.values(errors).some(error => error)}
                />
            </div>

            {/* Confirmation Modal */}
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onAction={handleConfirmCreation}
                title="Подтверждение создания кампании"
                actionLabel="Подтвердить"
                description="Вы уверены, что хотите создать эту кампанию?"
            />
        </div>
    );
};

export default CreateCampaignPage;
